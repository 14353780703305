@import 'styles/rsuite_components.css';
@import 'rsuite-table/dist/css/rsuite-table.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

/* tailwind doesn't style lists by default */
@layer base {

  ul,
  ol {
    list-style: revert;
  }
}

html,
body,
#root,
#root>div {
  min-height: 100vh;
  /* prevent macos swipe to navigate backwards */
  overscroll-behavior-x: none;
}

.Toastify {
  min-height: auto !important;
}

body {
  --colors-background: white;
  --colors-text: black;
  /* TODO: dark theme? */
  background-color: var(--colors-background);

  margin: 0;
  min-height: 100vh;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.rs-picker-toggle-value {
  color: var(--colors-text) !important;
}

.custom-selectpicker>.rs-picker-toggle {
  border-color: inherit !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* https://stackoverflow.com/questions/13014808/is-there-any-way-to-animate-an-ellipsis-with-css-animations */
.loading:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4, end) 900ms infinite;
  animation: ellipsis steps(4, end) 900ms infinite;
  content: "\2026";
  /* ascii code for the ellipsis character */
  width: 0px;
  margin-left: 10px;
}

@keyframes ellipsis {
  to {
    width: 40px;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 40px;
  }
}