.expand-chevron {
    cursor: pointer;   
    transition: transform 200ms ease-out;
}

.mobile-panel {
    max-height: 0;
    overflow: hidden;
    transition: max-height .25s ease-in-out;

    background-color: var(--colors-background);
}

.mobile-panel-expanded {
    max-height: 100vh;
}

.expanded {
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}
