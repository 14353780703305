:root,
.rs-theme-light {
  --rs-gray-50: #f7f7fa;
  --rs-gray-100: #f2f2f5;
  --rs-gray-200: #e5e5ea;
  --rs-gray-300: #d9d9d9;
  --rs-gray-400: #c5c6c7;
  --rs-gray-500: #a6a6a6;
  --rs-gray-600: #8e8e93;
  --rs-gray-700: #7a7a7a;
  --rs-gray-800: #575757;
  --rs-gray-900: #272c36;
  --rs-primary-50: #f2faff;
  --rs-primary-100: #cce9ff;
  --rs-primary-200: #a6d7ff;
  --rs-primary-300: #80c4ff;
  --rs-primary-400: #59afff;
  --rs-primary-500: #3498ff;
  --rs-primary-600: #2589f5;
  --rs-primary-700: #1675e0;
  --rs-primary-800: #0a5dc2;
  --rs-primary-900: #004299;
  --rs-red-50: #fff2f2;
  --rs-red-100: #fccfcf;
  --rs-red-200: #faa9a7;
  --rs-red-300: #fa8682;
  --rs-red-400: #f7635c;
  --rs-red-500: #f44336;
  --rs-red-600: #eb3626;
  --rs-red-700: #d62915;
  --rs-red-800: #b81c07;
  --rs-red-900: #8f1300;
  --rs-orange-50: #fff8f2;
  --rs-orange-100: #ffdfc2;
  --rs-orange-200: #fcc690;
  --rs-orange-300: #fcb160;
  --rs-orange-400: #fa9b2f;
  --rs-orange-500: #fa8900;
  --rs-orange-600: #f08800;
  --rs-orange-700: #db8000;
  --rs-orange-800: #bd7100;
  --rs-orange-900: #945b00;
  --rs-yellow-50: #fffaf2;
  --rs-yellow-100: #ffe9c2;
  --rs-yellow-200: #ffd991;
  --rs-yellow-300: #ffca61;
  --rs-yellow-400: #ffbe30;
  --rs-yellow-500: #ffb300;
  --rs-yellow-600: #f5af00;
  --rs-yellow-700: #e0a500;
  --rs-yellow-800: #c29100;
  --rs-yellow-900: #997500;
  --rs-green-50: #eeffed;
  --rs-green-100: #c8f0c7;
  --rs-green-200: #a5e0a4;
  --rs-green-300: #82cf82;
  --rs-green-400: #65bf67;
  --rs-green-500: #4caf50;
  --rs-green-600: #37ab3c;
  --rs-green-700: #22a12a;
  --rs-green-800: #0f9119;
  --rs-green-900: #007d0c;
  --rs-cyan-50: #f2ffff;
  --rs-cyan-100: #bcf4f7;
  --rs-cyan-200: #87e6ed;
  --rs-cyan-300: #57dae6;
  --rs-cyan-400: #2acadb;
  --rs-cyan-500: #00bcd4;
  --rs-cyan-600: #00b1cc;
  --rs-cyan-700: #00a0bd;
  --rs-cyan-800: #008aa6;
  --rs-cyan-900: #006e87;
  --rs-blue-50: #f0f9ff;
  --rs-blue-100: #c5e7fc;
  --rs-blue-200: #9bd4fa;
  --rs-blue-300: #72c0f7;
  --rs-blue-400: #49abf5;
  --rs-blue-500: #2196f3;
  --rs-blue-600: #1787e8;
  --rs-blue-700: #0d73d4;
  --rs-blue-800: #045cb5;
  --rs-blue-900: #00448c;
  --rs-violet-50: #f6f2ff;
  --rs-violet-100: #d5c9f0;
  --rs-violet-200: #b6a1e3;
  --rs-violet-300: #987bd4;
  --rs-violet-400: #805ac7;
  --rs-violet-500: #673ab7;
  --rs-violet-600: #5f2bb3;
  --rs-violet-700: #531ba8;
  --rs-violet-800: #470c99;
  --rs-violet-900: #390085;
  --rs-state-success: #4caf50;
  --rs-state-info: #2196f3;
  --rs-state-warning: #ffb300;
  --rs-state-error: #f44336;
  --rs-body: #fff;
  --rs-bg-success: #edfae1;
  --rs-bg-info: #e9f5fe;
  --rs-bg-warning: #fff9e6;
  --rs-bg-error: #fde9ef;
  --rs-text-link: #1675e0;
  --rs-text-link-hover: #0a5dc2;
  --rs-text-link-active: #004299;
  --rs-text-primary: #575757;
  --rs-text-secondary: #8e8e93;
  --rs-text-tertiary: #a6a6a6;
  --rs-text-heading: #272c36;
  --rs-text-inverse: #f7f7fa;
  --rs-text-heading-inverse: #fff;
  --rs-text-active: #1675e0;
  --rs-text-disabled: #c5c6c7;
  --rs-text-error: #f44336;
  --rs-border-primary: #e5e5ea;
  --rs-border-secondary: #f2f2f5;
  --rs-bg-card: #fff;
  --rs-bg-overlay: #fff;
  --rs-bg-well: #f7f7fa;
  --rs-bg-active: #3498ff;
  --rs-bg-backdrop: rgba(39, 44, 54, 0.3);
  --rs-state-hover-bg: #f2faff;
  --rs-color-focus-ring: rgba(52, 152, 255, 0.25);
  --rs-state-focus-shadow: 0 0 0 3px rgba(52, 152, 255, 0.25);
  --rs-state-focus-outline: 3px solid rgba(52, 152, 255, 0.25);
  --rs-shadow-overlay: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
  --rs-btn-default-bg: #f7f7fa;
  --rs-btn-default-text: #575757;
  --rs-btn-default-hover-bg: #e5e5ea;
  --rs-btn-default-active-bg: #d9d9d9;
  --rs-btn-default-active-text: #272c36;
  --rs-btn-default-disabled-bg: #f7f7fa;
  --rs-btn-default-disabled-text: #c5c6c7;
  --rs-btn-primary-bg: #3498ff;
  --rs-btn-primary-text: #fff;
  --rs-btn-primary-hover-bg: #2589f5;
  --rs-btn-primary-active-bg: #1675e0;
  --rs-btn-subtle-text: #8e8e93;
  --rs-btn-subtle-hover-bg: #f7f7fa;
  --rs-btn-subtle-hover-text: #575757;
  --rs-btn-subtle-active-bg: #e5e5ea;
  --rs-btn-subtle-active-text: #272c36;
  --rs-btn-subtle-disabled-text: #c5c6c7;
  --rs-btn-ghost-border: #1675e0;
  --rs-btn-ghost-text: #1675e0;
  --rs-btn-ghost-hover-border: #0a5dc2;
  --rs-btn-ghost-hover-text: #0a5dc2;
  --rs-btn-ghost-active-border: #004299;
  --rs-btn-ghost-active-text: #004299;
  --rs-btn-link-text: #1675e0;
  --rs-btn-link-hover-text: #0a5dc2;
  --rs-btn-link-active-text: #004299;
  --rs-iconbtn-addon: #f2f2f5;
  --rs-iconbtn-activated-addon: #d9d9d9;
  --rs-iconbtn-pressed-addon: #c5c6c7;
  --rs-iconbtn-primary-addon: #2589f5;
  --rs-iconbtn-primary-activated-addon: #1675e0;
  --rs-iconbtn-primary-pressed-addon: #0a5dc2;
  --rs-divider-border: #e5e5ea;
  --rs-loader-ring: rgba(247, 247, 250, 0.8);
  --rs-loader-rotor: #a6a6a6;
  --rs-loader-backdrop: rgba(255, 255, 255, 0.9);
  --rs-loader-ring-inverse: rgba(247, 247, 250, 0.3);
  --rs-loader-rotor-inverse: #fff;
  --rs-loader-backdrop-inverse: rgba(39, 44, 54, 0.83);
  --rs-message-success-header: var(--rs-text-heading);
  --rs-message-success-text: var(--rs-text-primary);
  --rs-message-success-icon: #4caf50;
  --rs-message-success-bg: #eeffed;
  --rs-message-info-header: var(--rs-text-heading);
  --rs-message-info-text: var(--rs-text-primary);
  --rs-message-info-icon: #2196f3;
  --rs-message-info-bg: #f0f9ff;
  --rs-message-warning-header: var(--rs-text-heading);
  --rs-message-warning-text: var(--rs-text-primary);
  --rs-message-warning-icon: #ffb300;
  --rs-message-warning-bg: #fffaf2;
  --rs-message-error-header: var(--rs-text-heading);
  --rs-message-error-text: var(--rs-text-primary);
  --rs-message-error-icon: #f44336;
  --rs-message-error-bg: #fff2f2;
  --rs-tooltip-bg: #272c36;
  --rs-tooltip-text: #fff;
  --rs-progress-bg: #e5e5ea;
  --rs-progress-bar: #3498ff;
  --rs-progress-bar-success: #4caf50;
  --rs-progress-bar-fail: #f44336;
  --rs-placeholder: #f2f2f5;
  --rs-placeholder-active: #e5e5ea;
  --rs-dropdown-divider: #e5e5ea;
  --rs-dropdown-item-bg-hover: rgba(204, 233, 255, 0.5);
  --rs-dropdown-item-bg-active: #f2faff;
  --rs-dropdown-item-text-active: #1675e0;
  --rs-dropdown-header-text: #a6a6a6;
  --rs-dropdown-shadow: 0 0 10px rgba(0, 0, 0, 0.06), 0 4px 4px rgba(0, 0, 0, 0.12);
  --rs-menuitem-active-bg: rgba(204, 233, 255, 0.5);
  --rs-menuitem-active-text: #1675e0;
  --rs-steps-border: #8e8e93;
  --rs-steps-state-finish: #3498ff;
  --rs-steps-border-state-finish: #3498ff;
  --rs-steps-state-wait: #8e8e93;
  --rs-steps-state-process: #3498ff;
  --rs-steps-state-error: #f44336;
  --rs-steps-border-state-error: #f44336;
  --rs-steps-icon-state-process: #3498ff;
  --rs-steps-icon-state-error: #f44336;
  --rs-navs-text: #8e8e93;
  --rs-navs-text-hover: #575757;
  --rs-navs-bg-hover: #e5e5ea;
  --rs-navs-text-active: #272c36;
  --rs-navs-bg-active: #e5e5ea;
  --rs-navs-tab-border: #d9d9d9;
  --rs-navs-subtle-border: #f7f7fa;
  --rs-navs-selected: #1675e0;
  --rs-navbar-default-bg: #f7f7fa;
  --rs-navbar-default-text: #575757;
  --rs-navbar-default-selected-text: #1675e0;
  --rs-navbar-default-hover-bg: #e5e5ea;
  --rs-navbar-default-hover-text: #575757;
  --rs-navbar-inverse-bg: #3498ff;
  --rs-navbar-inverse-text: #fff;
  --rs-navbar-inverse-selected-bg: #1675e0;
  --rs-navbar-inverse-hover-bg: #2589f5;
  --rs-navbar-inverse-hover-text: #fff;
  --rs-navbar-subtle-bg: #fff;
  --rs-navbar-subtle-text: #8e8e93;
  --rs-navbar-subtle-selected-text: #1675e0;
  --rs-navbar-subtle-hover-bg: #f7f7fa;
  --rs-navbar-subtle-hover-text: #575757;
  --rs-sidenav-default-bg: #f7f7fa;
  --rs-sidenav-default-text: #575757;
  --rs-sidenav-default-selected-text: #1675e0;
  --rs-sidenav-default-hover-bg: #e5e5ea;
  --rs-sidenav-default-hover-text: #575757;
  --rs-sidenav-default-footer-border: #e5e5ea;
  --rs-sidenav-inverse-bg: #3498ff;
  --rs-sidenav-inverse-text: #fff;
  --rs-sidenav-inverse-selected-bg: #1675e0;
  --rs-sidenav-inverse-hover-bg: #2589f5;
  --rs-sidenav-inverse-footer-border: #2589f5;
  --rs-sidenav-subtle-bg: #fff;
  --rs-sidenav-subtle-text: #8e8e93;
  --rs-sidenav-subtle-selected-text: #1675e0;
  --rs-sidenav-subtle-hover-bg: #f7f7fa;
  --rs-sidenav-subtle-hover-text: #575757;
  --rs-sidenav-subtle-footer-border: #e5e5ea;
  --rs-input-bg: #fff;
  --rs-input-focus-border: #3498ff;
  --rs-input-disabled-bg: #f7f7fa;
  --rs-listbox-option-hover-bg: rgba(204, 233, 255, 0.5);
  --rs-listbox-option-hover-text: #1675e0;
  --rs-listbox-option-selected-text: #1675e0;
  --rs-listbox-option-selected-bg: #f2faff;
  --rs-listbox-option-disabled-text: #c5c6c7;
  --rs-listbox-option-disabled-selected-text: #a6d7ff;
  --rs-checkbox-icon: #fff;
  --rs-checkbox-border: #d9d9d9;
  --rs-checkbox-checked-bg: #3498ff;
  --rs-checkbox-disabled-bg: #f7f7fa;
  --rs-radio-marker: #fff;
  --rs-radio-border: #d9d9d9;
  --rs-radio-checked-bg: #3498ff;
  --rs-radio-disabled-bg: #f7f7fa;
  --rs-rate-symbol: #8e8e93;
  --rs-rate-symbol-checked: #ffb300;
  --rs-toggle-bg: #d9d9d9;
  --rs-toggle-thumb: #fff;
  --rs-toggle-loader-ring: rgba(247, 247, 250, 0.3);
  --rs-toggle-loader-rotor: #fff;
  --rs-toggle-hover-bg: #c5c6c7;
  --rs-toggle-disabled-bg: #f7f7fa;
  --rs-toggle-disabled-thumb: #fff;
  --rs-toggle-checked-bg: #3498ff;
  --rs-toggle-checked-thumb: #fff;
  --rs-toggle-checked-hover-bg: #2589f5;
  --rs-toggle-checked-disabled-bg: #cce9ff;
  --rs-toggle-checked-disabled-thumb: #fff;
  --rs-slider-bar: #f2f2f5;
  --rs-slider-hover-bar: #e5e5ea;
  --rs-slider-thumb-border: #3498ff;
  --rs-slider-thumb-bg: #fff;
  --rs-slider-thumb-hover-shadow: 0 0 0 8px rgba(52, 152, 255, 0.25);
  --rs-slider-progress: #3498ff;
  --rs-uploader-item-bg: #d9d9d9;
  --rs-uploader-item-hover-bg: #f7f7fa;
  --rs-uploader-overlay-bg: rgba(255, 255, 255, 0.8);
  --rs-uploader-dnd-bg: #fff;
  --rs-uploader-dnd-border: #e5e5ea;
  --rs-uploader-dnd-hover-border: #3498ff;
  --rs-avatar-bg: #d9d9d9;
  --rs-avatar-text: #fff;
  --rs-badge-bg: #f44336;
  --rs-badge-text: #fff;
  --rs-tag-bg: #f7f7fa;
  --rs-tag-close: #f44336;
  --rs-carousel-bg: #8e8e93;
  --rs-carousel-indicator: rgba(255, 255, 255, 0.4);
  --rs-carousel-indicator-hover: #fff;
  --rs-carousel-indicator-active: #3498ff;
  --rs-panel-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
  --rs-list-bg: #fff;
  --rs-list-border: #e5e5ea;
  --rs-list-hover-bg: #f2faff;
  --rs-list-placeholder-bg: rgba(242, 250, 255, 0.5);
  --rs-list-placeholder-border: #3498ff;
  --rs-timeline-indicator-bg: #d9d9d9;
  --rs-timeline-indicator-active-bg: #3498ff;
  --rs-table-shadow: rgba(9, 9, 9, 0.08);
  --rs-table-sort: #3498ff;
  --rs-table-resize: #3498ff;
  --rs-table-scrollbar-track: #e5e5ea;
  --rs-table-scrollbar-thumb: #575757;
  --rs-table-scrollbar-thumb-active: #272c36;
  --rs-table-scrollbar-vertical-track: rgba(229, 229, 234, 0.4);
  --rs-drawer-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
  --rs-modal-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
  --rs-form-errormessage-text: #f44336;
  --rs-form-errormessage-bg: #fff;
  --rs-form-errormessage-border: #e5e5ea;
  --rs-picker-value: #1675e0;
  --rs-picker-count-bg: #3498ff;
  --rs-picker-count-text: #fff;
  --rs-calendar-today-bg: #3498ff;
  --rs-calendar-today-text: #fff;
  --rs-calendar-range-bg: rgba(204, 233, 255, 0.5);
  --rs-calendar-time-unit-bg: #f7f7fa;
  --rs-calendar-date-selected-text: #fff;
  --rs-popover-shadow: 0 1px 8px rgba(0, 0, 0, 0.12);
}
.rs-theme-dark {
  --rs-gray-50: #e9ebf0;
  --rs-gray-100: #cbced4;
  --rs-gray-200: #a4a9b3;
  --rs-gray-300: #858b94;
  --rs-gray-400: #6a6f76;
  --rs-gray-500: #5c6066;
  --rs-gray-600: #3c3f43;
  --rs-gray-700: #292d33;
  --rs-gray-800: #1a1d24;
  --rs-gray-900: #0f131a;
  --rs-primary-50: #f2fcff;
  --rs-primary-100: #ccf3ff;
  --rs-primary-200: #a6e9ff;
  --rs-primary-300: #80ddff;
  --rs-primary-400: #59d0ff;
  --rs-primary-500: #34c3ff;
  --rs-primary-600: #25b3f5;
  --rs-primary-700: #169de0;
  --rs-primary-800: #0a81c2;
  --rs-primary-900: #006199;
  --rs-red-50: #ffeded;
  --rs-red-100: #fccaca;
  --rs-red-200: #faa9a7;
  --rs-red-300: #f58884;
  --rs-red-400: #f26a63;
  --rs-red-500: #f04f43;
  --rs-red-600: #e63f30;
  --rs-red-700: #d12f1d;
  --rs-red-800: #b3200c;
  --rs-red-900: #8a1200;
  --rs-orange-50: #fff9f2;
  --rs-orange-100: #ffe2c2;
  --rs-orange-200: #ffce91;
  --rs-orange-300: #ffba61;
  --rs-orange-400: #ffa930;
  --rs-orange-500: #ff9800;
  --rs-orange-600: #f59700;
  --rs-orange-700: #e08e00;
  --rs-orange-800: #c27e00;
  --rs-orange-900: #996600;
  --rs-yellow-50: #fff9f0;
  --rs-yellow-100: #ffedd1;
  --rs-yellow-200: #ffe2b3;
  --rs-yellow-300: #ffd894;
  --rs-yellow-400: #ffcf75;
  --rs-yellow-500: #ffc757;
  --rs-yellow-600: #f5bb3d;
  --rs-yellow-700: #e0a824;
  --rs-yellow-800: #c28f0e;
  --rs-yellow-900: #997000;
  --rs-green-50: #f3fff2;
  --rs-green-100: #cff0ce;
  --rs-green-200: #aee0ad;
  --rs-green-300: #8ccf8c;
  --rs-green-400: #71bf72;
  --rs-green-500: #58b15b;
  --rs-green-600: #3fab45;
  --rs-green-700: #27a12f;
  --rs-green-800: #10911b;
  --rs-green-900: #007d0c;
  --rs-cyan-50: #f2ffff;
  --rs-cyan-100: #bcf4f7;
  --rs-cyan-200: #87e6ed;
  --rs-cyan-300: #57dae6;
  --rs-cyan-400: #2acadb;
  --rs-cyan-500: #00bcd4;
  --rs-cyan-600: #00b1cc;
  --rs-cyan-700: #00a0bd;
  --rs-cyan-800: #008aa6;
  --rs-cyan-900: #006e87;
  --rs-blue-50: #edf9ff;
  --rs-blue-100: #c0e8fc;
  --rs-blue-200: #93d6fa;
  --rs-blue-300: #67c1f5;
  --rs-blue-400: #3dadf2;
  --rs-blue-500: #1499ef;
  --rs-blue-600: #0e8ce6;
  --rs-blue-700: #087ad1;
  --rs-blue-800: #0464b3;
  --rs-blue-900: #00498a;
  --rs-violet-50: #f6f2ff;
  --rs-violet-100: #d5c9f0;
  --rs-violet-200: #b6a1e3;
  --rs-violet-300: #987bd4;
  --rs-violet-400: #805ac7;
  --rs-violet-500: #673ab7;
  --rs-violet-600: #5f2bb3;
  --rs-violet-700: #531ba8;
  --rs-violet-800: #470c99;
  --rs-violet-900: #390085;
  --rs-state-success: #58b15b;
  --rs-state-info: #1499ef;
  --rs-state-warning: #ffc757;
  --rs-state-error: #f04f43;
  --rs-body: #0f131a;
  --rs-text-link: #34c3ff;
  --rs-text-link-hover: #59d0ff;
  --rs-text-link-active: #80ddff;
  --rs-text-primary: #e9ebf0;
  --rs-text-secondary: #a4a9b3;
  --rs-text-tertiary: #858b94;
  --rs-text-heading: #fff;
  --rs-text-inverse: #1a1d24;
  --rs-text-heading-inverse: #0f131a;
  --rs-text-active: #34c3ff;
  --rs-text-disabled: #5c6066;
  --rs-border-primary: #3c3f43;
  --rs-border-secondary: #292d33;
  --rs-bg-card: #1a1d24;
  --rs-bg-overlay: #292d33;
  --rs-bg-well: #0f131a;
  --rs-bg-active: #169de0;
  --rs-bg-backdrop: rgba(15, 19, 26, 0.8);
  --rs-state-hover-bg: #3c3f43;
  --rs-color-focus-ring: 0 0 0 3px rgba(52, 195, 255, 0.25);
  --rs-state-focus-shadow: 0 0 0 3px rgba(52, 195, 255, 0.25);
  --rs-state-focus-outline: 3px solid rgba(52, 195, 255, 0.25);
  --rs-shadow-overlay: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
  --rs-btn-default-bg: #3c3f43;
  --rs-btn-default-text: #e9ebf0;
  --rs-btn-default-hover-bg: #5c6066;
  --rs-btn-default-active-bg: #858b94;
  --rs-btn-default-active-text: #fff;
  --rs-btn-default-disabled-bg: #3c3f43;
  --rs-btn-default-disabled-text: #5c6066;
  --rs-btn-primary-bg: #169de0;
  --rs-btn-primary-text: #fff;
  --rs-btn-primary-hover-bg: #25b3f5;
  --rs-btn-primary-active-bg: #59d0ff;
  --rs-btn-subtle-text: #a4a9b3;
  --rs-btn-subtle-hover-bg: #292d33;
  --rs-btn-subtle-hover-text: #e9ebf0;
  --rs-btn-subtle-active-bg: #6a6f76;
  --rs-btn-subtle-active-text: #fff;
  --rs-btn-subtle-disabled-text: #5c6066;
  --rs-btn-ghost-border: #34c3ff;
  --rs-btn-ghost-text: #34c3ff;
  --rs-btn-ghost-hover-border: #59d0ff;
  --rs-btn-ghost-hover-text: #59d0ff;
  --rs-btn-ghost-active-border: #a6e9ff;
  --rs-btn-ghost-active-text: #a6e9ff;
  --rs-btn-link-text: #34c3ff;
  --rs-btn-link-hover-text: #59d0ff;
  --rs-btn-link-active-text: #a6e9ff;
  --rs-iconbtn-addon: #5c6066;
  --rs-iconbtn-activated-addon: #6a6f76;
  --rs-iconbtn-pressed-addon: #a4a9b3;
  --rs-iconbtn-primary-addon: #25b3f5;
  --rs-iconbtn-primary-activated-addon: #34c3ff;
  --rs-iconbtn-primary-pressed-addon: #59d0ff;
  --rs-divider-border: #3c3f43;
  --rs-loader-ring: rgba(233, 235, 240, 0.3);
  --rs-loader-rotor: #fff;
  --rs-loader-backdrop: rgba(15, 19, 26, 0.83);
  --rs-loader-ring-inverse: rgba(233, 235, 240, 0.8);
  --rs-loader-rotor-inverse: #5c6066;
  --rs-loader-backdrop-inverse: rgba(255, 255, 255, 0.9);
  --rs-message-success-header: #fff;
  --rs-message-success-text: #fff;
  --rs-message-success-icon: #fff;
  --rs-message-success-bg: #4caf50;
  --rs-message-info-header: #fff;
  --rs-message-info-text: #fff;
  --rs-message-info-icon: #fff;
  --rs-message-info-bg: #2196f3;
  --rs-message-warning-header: #0f131a;
  --rs-message-warning-text: #0f131a;
  --rs-message-warning-icon: #0f131a;
  --rs-message-warning-bg: #ffb300;
  --rs-message-error-header: #fff;
  --rs-message-error-text: #fff;
  --rs-message-error-icon: #fff;
  --rs-message-error-bg: #f44336;
  --rs-tooltip-bg: #5c6066;
  --rs-tooltip-text: #fff;
  --rs-progress-bg: #292d33;
  --rs-progress-bar: #34c3ff;
  --rs-progress-bar-success: #4caf50;
  --rs-progress-bar-fail: #f44336;
  --rs-placeholder: #3c3f43;
  --rs-placeholder-active: #484c50;
  --rs-dropdown-divider: #3c3f43;
  --rs-dropdown-item-bg-hover: #3c3f43;
  --rs-dropdown-item-bg-active: rgba(0, 97, 153, 0.2);
  --rs-dropdown-item-text-active: #34c3ff;
  --rs-dropdown-header-text: #5c6066;
  --rs-dropdown-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.2), 0 4px 4px 3px rgba(0, 0, 0, 0.24);
  --rs-menuitem-active-bg: #3c3f43;
  --rs-menuitem-active-text: currentColor;
  --rs-steps-border: #a4a9b3;
  --rs-steps-state-finish: #34c3ff;
  --rs-steps-border-state-finish: #34c3ff;
  --rs-steps-state-wait: #a4a9b3;
  --rs-steps-state-process: #169de0;
  --rs-steps-state-error: #f44336;
  --rs-steps-border-state-error: #f44336;
  --rs-steps-icon-state-process: #34c3ff;
  --rs-steps-icon-state-error: #f44336;
  --rs-navs-text: #a4a9b3;
  --rs-navs-text-hover: #cbced4;
  --rs-navs-bg-hover: #6a6f76;
  --rs-navs-text-active: #fff;
  --rs-navs-bg-active: #6a6f76;
  --rs-navs-tab-border: #3c3f43;
  --rs-navs-subtle-border: #3c3f43;
  --rs-navs-selected: #34c3ff;
  --rs-navbar-default-bg: #1a1d24;
  --rs-navbar-default-text: #a4a9b3;
  --rs-navbar-default-selected-text: #34c3ff;
  --rs-navbar-default-hover-bg: #292d33;
  --rs-navbar-default-hover-text: #e9ebf0;
  --rs-navbar-inverse-bg: #169de0;
  --rs-navbar-inverse-text: #fff;
  --rs-navbar-inverse-selected-bg: #59d0ff;
  --rs-navbar-inverse-hover-bg: #25b3f5;
  --rs-navbar-inverse-hover-text: #fff;
  --rs-navbar-subtle-bg: transparent;
  --rs-navbar-subtle-text: #a4a9b3;
  --rs-navbar-subtle-selected-text: #34c3ff;
  --rs-navbar-subtle-hover-bg: #292d33;
  --rs-navbar-subtle-hover-text: #e9ebf0;
  --rs-sidenav-default-bg: #1a1d24;
  --rs-sidenav-default-text: #a4a9b3;
  --rs-sidenav-default-selected-text: #34c3ff;
  --rs-sidenav-default-hover-bg: #292d33;
  --rs-sidenav-default-hover-text: #e9ebf0;
  --rs-sidenav-default-footer-border: #3c3f43;
  --rs-sidenav-inverse-bg: #169de0;
  --rs-sidenav-inverse-text: #fff;
  --rs-sidenav-inverse-selected-bg: #59d0ff;
  --rs-sidenav-inverse-hover-bg: #25b3f5;
  --rs-sidenav-inverse-footer-border: #25b3f5;
  --rs-sidenav-subtle-bg: transparent;
  --rs-sidenav-subtle-text: #a4a9b3;
  --rs-sidenav-subtle-selected-text: #34c3ff;
  --rs-sidenav-subtle-hover-bg: #292d33;
  --rs-sidenav-subtle-hover-text: #e9ebf0;
  --rs-sidenav-subtle-footer-border: #3c3f43;
  --rs-input-bg: #1a1d24;
  --rs-input-focus-border: #34c3ff;
  --rs-input-disabled-bg: #292d33;
  --rs-listbox-option-hover-bg: #3c3f43;
  --rs-listbox-option-hover-text: currentColor;
  --rs-listbox-option-selected-text: #34c3ff;
  --rs-listbox-option-selected-bg: rgba(0, 97, 153, 0.2);
  --rs-listbox-option-disabled-text: #5c6066;
  --rs-listbox-option-disabled-selected-text: #a6e9ff;
  --rs-checkbox-icon: #1a1d24;
  --rs-checkbox-border: #6a6f76;
  --rs-checkbox-checked-bg: #34c3ff;
  --rs-checkbox-disabled-bg: #5c6066;
  --rs-radio-marker: #1a1d24;
  --rs-radio-border: #6a6f76;
  --rs-radio-checked-bg: #34c3ff;
  --rs-radio-disabled-bg: #5c6066;
  --rs-rate-symbol: #3c3f43;
  --rs-rate-symbol-checked: #ffb300;
  --rs-toggle-bg: #6a6f76;
  --rs-toggle-thumb: #fff;
  --rs-toggle-hover-bg: #858b94;
  --rs-toggle-disabled-bg: #3c3f43;
  --rs-toggle-disabled-thumb: #5c6066;
  --rs-toggle-checked-bg: #169de0;
  --rs-toggle-checked-thumb: #fff;
  --rs-toggle-checked-hover-bg: #25b3f5;
  --rs-toggle-checked-disabled-bg: #006199;
  --rs-toggle-checked-disabled-thumb: #858b94;
  --rs-slider-bar: #3c3f43;
  --rs-slider-hover-bar: #3c3f43;
  --rs-slider-thumb-border: #34c3ff;
  --rs-slider-thumb-bg: #292d33;
  --rs-slider-thumb-hover-shadow: 0 0 0 8px rgba(52, 195, 255, 0.25);
  --rs-slider-progress: #34c3ff;
  --rs-uploader-item-bg: #858b94;
  --rs-uploader-item-hover-bg: #3c3f43;
  --rs-uploader-overlay-bg: rgba(60, 63, 67, 0.8);
  --rs-uploader-dnd-bg: #292d33;
  --rs-uploader-dnd-border: #a4a9b3;
  --rs-uploader-dnd-hover-border: #34c3ff;
  --rs-avatar-bg: #6a6f76;
  --rs-avatar-text: #fff;
  --rs-badge-bg: #f44336;
  --rs-badge-text: #fff;
  --rs-tag-bg: #3c3f43;
  --rs-tag-close: #f44336;
  --rs-carousel-bg: #3c3f43;
  --rs-carousel-indicator: rgba(255, 255, 255, 0.4);
  --rs-carousel-indicator-hover: #fff;
  --rs-carousel-indicator-active: #34c3ff;
  --rs-panel-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
  --rs-list-bg: #0f131a;
  --rs-list-border: #292d33;
  --rs-list-hover-bg: #3c3f43;
  --rs-list-placeholder-bg: rgba(0, 97, 153, 0.2);
  --rs-list-placeholder-border: #34c3ff;
  --rs-timeline-indicator-bg: #5c6066;
  --rs-timeline-indicator-active-bg: #34c3ff;
  --rs-table-shadow: rgba(9, 9, 9, 0.99);
  --rs-table-sort: #34c3ff;
  --rs-table-resize: #34c3ff;
  --rs-table-scrollbar-track: #292d33;
  --rs-table-scrollbar-thumb: #a4a9b3;
  --rs-table-scrollbar-thumb-active: #cbced4;
  --rs-table-scrollbar-vertical-track: #292d33;
  --rs-drawer-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
  --rs-modal-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
  --rs-form-errormessage-text: #fff;
  --rs-form-errormessage-bg: #f44336;
  --rs-form-errormessage-border: #f44336;
  --rs-picker-value: #34c3ff;
  --rs-picker-count-bg: #169de0;
  --rs-picker-count-text: #fff;
  --rs-calendar-today-bg: #169de0;
  --rs-calendar-today-text: #fff;
  --rs-calendar-range-bg: rgba(0, 97, 153, 0.5);
  --rs-calendar-time-unit-bg: #3c3f43;
  --rs-calendar-date-selected-text: #fff;
  --rs-popover-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
}
.rs-theme-high-contrast {
  --rs-gray-50: #e9ebf0;
  --rs-gray-100: #cbced4;
  --rs-gray-200: #a4a9b3;
  --rs-gray-300: #858b94;
  --rs-gray-400: #6a6f76;
  --rs-gray-500: #5c6066;
  --rs-gray-600: #3c3f43;
  --rs-gray-700: #292d33;
  --rs-gray-800: #1a1d24;
  --rs-gray-900: #0f131a;
  --rs-primary-50: #fffef2;
  --rs-primary-100: #fffbc2;
  --rs-primary-200: #fffa91;
  --rs-primary-300: #fffa61;
  --rs-primary-400: #fffc30;
  --rs-primary-500: #ffff00;
  --rs-primary-600: #f1f500;
  --rs-primary-700: #d9e000;
  --rs-primary-800: #b8c200;
  --rs-primary-900: #8f9900;
  --rs-red-50: #fff2f5;
  --rs-red-100: #f2c2cd;
  --rs-red-200: #e691a3;
  --rs-red-300: #d6637a;
  --rs-red-400: #c93a55;
  --rs-red-500: #bd1732;
  --rs-red-600: #b51029;
  --rs-red-700: #a60a1f;
  --rs-red-800: #8f0414;
  --rs-red-900: #70000b;
  --rs-orange-50: #fff9f2;
  --rs-orange-100: #ffe2c2;
  --rs-orange-200: #ffce91;
  --rs-orange-300: #ffba61;
  --rs-orange-400: #ffa930;
  --rs-orange-500: #ff9800;
  --rs-orange-600: #f59700;
  --rs-orange-700: #e08e00;
  --rs-orange-800: #c27e00;
  --rs-orange-900: #996600;
  --rs-yellow-50: #fff9f0;
  --rs-yellow-100: #ffedd1;
  --rs-yellow-200: #ffe2b3;
  --rs-yellow-300: #ffd894;
  --rs-yellow-400: #ffcf75;
  --rs-yellow-500: #ffc757;
  --rs-yellow-600: #f5bb3d;
  --rs-yellow-700: #e0a824;
  --rs-yellow-800: #c28f0e;
  --rs-yellow-900: #997000;
  --rs-green-50: #f2fff5;
  --rs-green-100: #b3e6bd;
  --rs-green-200: #7ccc8e;
  --rs-green-300: #50b567;
  --rs-green-400: #2a9c46;
  --rs-green-500: #0d822c;
  --rs-green-600: #09802b;
  --rs-green-700: #057a28;
  --rs-green-800: #017325;
  --rs-green-900: #006923;
  --rs-cyan-50: #f2ffff;
  --rs-cyan-100: #bcf4f7;
  --rs-cyan-200: #87e6ed;
  --rs-cyan-300: #57dae6;
  --rs-cyan-400: #2acadb;
  --rs-cyan-500: #00bcd4;
  --rs-cyan-600: #00b1cc;
  --rs-cyan-700: #00a0bd;
  --rs-cyan-800: #008aa6;
  --rs-cyan-900: #006e87;
  --rs-blue-50: #edf9ff;
  --rs-blue-100: #c0e8fc;
  --rs-blue-200: #93d6fa;
  --rs-blue-300: #67c1f5;
  --rs-blue-400: #3dadf2;
  --rs-blue-500: #1499ef;
  --rs-blue-600: #0e8ce6;
  --rs-blue-700: #087ad1;
  --rs-blue-800: #0464b3;
  --rs-blue-900: #00498a;
  --rs-violet-50: #f6f2ff;
  --rs-violet-100: #d5c9f0;
  --rs-violet-200: #b6a1e3;
  --rs-violet-300: #987bd4;
  --rs-violet-400: #805ac7;
  --rs-violet-500: #673ab7;
  --rs-violet-600: #5f2bb3;
  --rs-violet-700: #531ba8;
  --rs-violet-800: #470c99;
  --rs-violet-900: #390085;
  --rs-state-success: #0d822c;
  --rs-state-info: #1499ef;
  --rs-state-warning: #ffc757;
  --rs-state-error: #bd1732;
  --rs-body: #0f131a;
  --rs-text-link: #ffff00;
  --rs-text-link-hover: #fffc30;
  --rs-text-link-active: #fffa61;
  --rs-text-primary: #e9ebf0;
  --rs-text-secondary: #a4a9b3;
  --rs-text-tertiary: #858b94;
  --rs-text-heading: #fff;
  --rs-text-inverse: #1a1d24;
  --rs-text-heading-inverse: #0f131a;
  --rs-text-active: #ffff00;
  --rs-text-disabled: #5c6066;
  --rs-border-primary: #cbced4;
  --rs-border-secondary: #292d33;
  --rs-bg-card: #1a1d24;
  --rs-bg-overlay: #1a1d24;
  --rs-bg-well: #0f131a;
  --rs-bg-active: #ffff00;
  --rs-bg-backdrop: rgba(15, 19, 26, 0.8);
  --rs-state-hover-bg: #3c3f43;
  --rs-color-focus-ring: #fff;
  --rs-state-focus-shadow: 0 0 0 3px #0f131a, 0 0 0 5px #fff;
  --rs-state-focus-shadow-slim: 0 0 0 2px #fff;
  --rs-state-focus-outline: 3px solid rgba(255, 255, 0, 0.25);
  --rs-shadow-overlay: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
  --rs-btn-default-bg: transparent;
  --rs-btn-default-text: #ffff00;
  --rs-btn-default-border: 1px solid #ffff00;
  --rs-btn-default-hover-bg: transparent;
  --rs-btn-default-hover-text: #fffc30;
  --rs-btn-default-active-bg: transparent;
  --rs-btn-default-active-text: #fffa91;
  --rs-btn-default-disabled-bg: transparent;
  --rs-btn-default-disabled-text: #ffff00;
  --rs-btn-primary-bg: #ffff00;
  --rs-btn-primary-text: #0f131a;
  --rs-btn-primary-hover-bg: #fffc30;
  --rs-btn-primary-active-bg: #fffa91;
  --rs-btn-subtle-text: #ffff00;
  --rs-btn-subtle-hover-bg: transparent;
  --rs-btn-subtle-hover-text: #fffc30;
  --rs-btn-subtle-active-bg: transparent;
  --rs-btn-subtle-active-text: #fffa91;
  --rs-btn-subtle-disabled-text: #5c6066;
  --rs-btn-ghost-border: #ffff00;
  --rs-btn-ghost-text: #ffff00;
  --rs-btn-ghost-hover-border: #fffc30;
  --rs-btn-ghost-hover-text: #fffc30;
  --rs-btn-ghost-active-border: #fffa91;
  --rs-btn-ghost-active-text: #fffa91;
  --rs-btn-link-text: #ffff00;
  --rs-btn-link-hover-text: #fffc30;
  --rs-btn-link-active-text: #fffa91;
  --rs-iconbtn-addon: transparent;
  --rs-iconbtn-activated-addon: transparent;
  --rs-iconbtn-pressed-addon: transparent;
  --rs-iconbtn-primary-addon: #fffc30;
  --rs-iconbtn-primary-activated-addon: #fffa61;
  --rs-iconbtn-primary-pressed-addon: #fffbc2;
  --rs-divider-border: #3c3f43;
  --rs-loader-ring: rgba(233, 235, 240, 0.3);
  --rs-loader-rotor: #fff;
  --rs-loader-backdrop: rgba(15, 19, 26, 0.83);
  --rs-loader-ring-inverse: rgba(233, 235, 240, 0.8);
  --rs-loader-rotor-inverse: #5c6066;
  --rs-loader-backdrop-inverse: rgba(255, 255, 255, 0.9);
  --rs-message-success-header: #fff;
  --rs-message-success-text: #fff;
  --rs-message-success-icon: #fff;
  --rs-message-success-bg: #007d0c;
  --rs-message-success-border: #82cf82;
  --rs-message-info-header: #fff;
  --rs-message-info-text: #fff;
  --rs-message-info-icon: #fff;
  --rs-message-info-bg: #00448c;
  --rs-message-info-border: #2196f3;
  --rs-message-warning-header: #fff;
  --rs-message-warning-text: #fff;
  --rs-message-warning-icon: #fff;
  --rs-message-warning-bg: #997500;
  --rs-message-warning-border: #ffb300;
  --rs-message-error-header: #fff;
  --rs-message-error-text: #fff;
  --rs-message-error-icon: #fff;
  --rs-message-error-bg: #8f1300;
  --rs-message-error-border: #fa8682;
  --rs-tooltip-bg: #1a1d24;
  --rs-tooltip-text: #fff;
  --rs-progress-bg: #292d33;
  --rs-progress-bar: #ffff00;
  --rs-progress-bar-success: #4caf50;
  --rs-progress-bar-fail: #f44336;
  --rs-placeholder: #3c3f43;
  --rs-placeholder-active: #484c50;
  --rs-dropdown-divider: #3c3f43;
  --rs-dropdown-item-bg-hover: #3c3f43;
  --rs-dropdown-item-bg-active: rgba(143, 153, 0, 0.2);
  --rs-dropdown-item-text-active: #ffff00;
  --rs-dropdown-header-text: #5c6066;
  --rs-dropdown-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.2), 0 4px 4px 3px rgba(0, 0, 0, 0.24);
  --rs-menuitem-active-bg: transparent;
  --rs-menuitem-active-text: #ffff00;
  --rs-steps-border: #a4a9b3;
  --rs-steps-state-finish: #ffff00;
  --rs-steps-border-state-finish: #ffff00;
  --rs-steps-state-wait: #a4a9b3;
  --rs-steps-state-process: #d9e000;
  --rs-steps-state-error: #f44336;
  --rs-steps-border-state-error: #f44336;
  --rs-steps-icon-state-process: #ffff00;
  --rs-steps-icon-state-error: #f44336;
  --rs-navs-text: #a4a9b3;
  --rs-navs-text-hover: #ffff00;
  --rs-navs-bg-hover: transparent;
  --rs-navs-text-active: #ffff00;
  --rs-navs-bg-active: #6a6f76;
  --rs-navs-tab-border: #3c3f43;
  --rs-navs-subtle-border: #3c3f43;
  --rs-navs-selected: #ffff00;
  --rs-navbar-default-bg: #1a1d24;
  --rs-navbar-default-text: #e9ebf0;
  --rs-navbar-default-selected-text: #ffff00;
  --rs-navbar-default-hover-bg: transparent;
  --rs-navbar-default-hover-text: #ffff00;
  --rs-navbar-inverse-bg: #1a1d24;
  --rs-navbar-inverse-text: #e9ebf0;
  --rs-navbar-inverse-selected-bg: transparent;
  --rs-navbar-inverse-selected-text: #ffff00;
  --rs-navbar-inverse-hover-bg: transparent;
  --rs-navbar-inverse-hover-text: #ffff00;
  --rs-navbar-subtle-bg: #1a1d24;
  --rs-navbar-subtle-text: #e9ebf0;
  --rs-navbar-subtle-selected-text: #ffff00;
  --rs-navbar-subtle-hover-bg: transparent;
  --rs-navbar-subtle-hover-text: #ffff00;
  --rs-sidenav-default-bg: #1a1d24;
  --rs-sidenav-default-text: #e9ebf0;
  --rs-sidenav-default-selected-text: #ffff00;
  --rs-sidenav-default-hover-bg: transparent;
  --rs-sidenav-default-hover-text: #ffff00;
  --rs-sidenav-default-footer-border: #e9ebf0;
  --rs-sidenav-inverse-bg: #1a1d24;
  --rs-sidenav-inverse-text: #e9ebf0;
  --rs-sidenav-inverse-selected-bg: transparent;
  --rs-sidenav-inverse-selected-text: #ffff00;
  --rs-sidenav-inverse-hover-bg: transparent;
  --rs-sidenav-inverse-footer-border: #e9ebf0;
  --rs-sidenav-subtle-bg: #1a1d24;
  --rs-sidenav-subtle-text: #e9ebf0;
  --rs-sidenav-subtle-selected-text: #ffff00;
  --rs-sidenav-subtle-hover-bg: transparent;
  --rs-sidenav-subtle-hover-text: #ffff00;
  --rs-sidenav-subtle-footer-border: #e9ebf0;
  --rs-input-bg: #1a1d24;
  --rs-input-focus-border: #ffff00;
  --rs-input-disabled-bg: #292d33;
  --rs-listbox-option-hover-bg: transparent;
  --rs-listbox-option-hover-text: #ffff00;
  --rs-listbox-option-selected-text: #ffff00;
  --rs-listbox-option-selected-bg: transparent;
  --rs-listbox-option-disabled-text: #5c6066;
  --rs-listbox-option-disabled-selected-text: #fffa91;
  --rs-checkbox-icon: #1a1d24;
  --rs-checkbox-border: #cbced4;
  --rs-checkbox-checked-bg: #ffff00;
  --rs-checkbox-disabled-bg: #5c6066;
  --rs-radio-marker: #1a1d24;
  --rs-radio-border: #cbced4;
  --rs-radio-checked-bg: #ffff00;
  --rs-radio-disabled-bg: #5c6066;
  --rs-rate-symbol: #cbced4;
  --rs-rate-symbol-checked: #ffff00;
  --rs-toggle-bg: #1a1d24;
  --rs-toggle-thumb: #cbced4;
  --rs-toggle-hover-bg: #1a1d24;
  --rs-toggle-disabled-bg: #1a1d24;
  --rs-toggle-disabled-thumb: #858b94;
  --rs-toggle-checked-bg: #ffff00;
  --rs-toggle-checked-thumb: #1a1d24;
  --rs-toggle-checked-hover-bg: #fffc30;
  --rs-toggle-checked-disabled-bg: #8f9900;
  --rs-toggle-checked-disabled-thumb: #1a1d24;
  --rs-slider-bar: #3c3f43;
  --rs-slider-hover-bar: #3c3f43;
  --rs-slider-thumb-border: #ffff00;
  --rs-slider-thumb-bg: #292d33;
  --rs-slider-thumb-hover-shadow: 0 0 0 8px rgba(255, 255, 0, 0.25);
  --rs-slider-progress: #ffff00;
  --rs-uploader-item-bg: #858b94;
  --rs-uploader-item-hover-bg: #1a1d24;
  --rs-uploader-item-hover-text: #ffff00;
  --rs-uploader-overlay-bg: rgba(60, 63, 67, 0.8);
  --rs-uploader-dnd-bg: #292d33;
  --rs-uploader-dnd-border: #a4a9b3;
  --rs-uploader-dnd-hover-border: #ffff00;
  --rs-avatar-bg: #6a6f76;
  --rs-avatar-text: #fff;
  --rs-badge-bg: #f44336;
  --rs-badge-text: #fff;
  --rs-tag-bg: #3c3f43;
  --rs-tag-close: #f44336;
  --rs-carousel-bg: #3c3f43;
  --rs-carousel-indicator: rgba(255, 255, 255, 0.4);
  --rs-carousel-indicator-hover: #fff;
  --rs-carousel-indicator-active: #ffff00;
  --rs-panel-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
  --rs-pagination-item-text: #e9ebf0;
  --rs-pagination-item-current-text: #ffff00;
  --rs-list-bg: transparent;
  --rs-list-border: #292d33;
  --rs-list-hover-bg: #3c3f43;
  --rs-list-placeholder-bg: rgba(143, 153, 0, 0.2);
  --rs-list-placeholder-border: #ffff00;
  --rs-timeline-indicator-bg: #5c6066;
  --rs-timeline-indicator-active-bg: #ffff00;
  --rs-table-shadow: rgba(9, 9, 9, 0.99);
  --rs-table-sort: #ffff00;
  --rs-table-resize: #ffff00;
  --rs-table-scrollbar-track: #292d33;
  --rs-table-scrollbar-thumb: #a4a9b3;
  --rs-table-scrollbar-thumb-active: #cbced4;
  --rs-table-scrollbar-vertical-track: #292d33;
  --rs-drawer-bg: #292d33;
  --rs-drawer-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
  --rs-modal-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
  --rs-modal-border: 1px solid #cbced4;
  --rs-form-errormessage-text: #fff;
  --rs-form-errormessage-bg: #f44336;
  --rs-form-errormessage-border: #f44336;
  --rs-picker-value: #ffff00;
  --rs-picker-count-bg: #ffff00;
  --rs-picker-count-text: #0f131a;
  --rs-calendar-today-bg: #ffff00;
  --rs-calendar-today-text: #0f131a;
  --rs-calendar-range-bg: rgba(143, 153, 0, 0.5);
  --rs-calendar-time-unit-bg: #0f131a;
  --rs-calendar-date-selected-text: #0f131a;
  --rs-popover-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
}
/* stylelint-disable */
* {
  box-sizing: border-box;
}
*::before,
*::after {
  box-sizing: border-box;
}
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
[hidden],
template {
  display: none;
}
a {
  background-color: transparent;
}
a:active,
a:hover {
  outline: 0;
}
b,
strong {
  font-weight: bold;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sup {
  top: -0.5em;
}
sub {
  bottom: -0.25em;
}
img {
  border: 0;
}
svg:not(:root) {
  overflow: hidden;
}
hr {
  box-sizing: content-box;
  height: 0;
}
pre {
  overflow: auto;
}
button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}
button {
  overflow: visible;
}
button {
  border-radius: 0;
}
button,
select {
  text-transform: none;
}
button,
html input[type='button'],
input[type='reset'],
input[type='submit'] {
  -webkit-appearance: button;
  cursor: pointer;
}
button[disabled],
html input[disabled] {
  cursor: not-allowed;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
input {
  line-height: normal;
}
input[type='checkbox'],
input[type='radio'] {
  box-sizing: border-box;
  padding: 0;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  height: auto;
}
textarea {
  overflow: auto;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
td,
th {
  padding: 0;
}
[tabindex='-1'] {
  outline: none;
}
input[type='button'],
input[type='submit'],
input[type='reset'],
button {
  border-width: 0;
}
input::-ms-clear {
  display: none;
}
input[type='file']::-webkit-file-upload-button,
input[type='file']::-ms-browse {
  border-width: 0;
  background: transparent;
  color: currentColor;
}
input,
button,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
.rs-picker-check-menu-items {
  margin-bottom: 6px;
  overflow-y: auto;
}
.rs-picker-check-menu-items ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.rs-picker-check-menu-items .rs-picker-menu-group:not(:first-child) {
  margin-top: 6px;
  border-top: 1px solid var(--rs-border-primary);
  padding-top: 6px;
}
.rs-picker-check-menu-items .rs-picker-menu-group-title {
  padding: 8px 12px;
  padding-right: 32px;
  position: relative;
  cursor: default;
  transition: color 0.3s linear, background-color 0.3s linear;
  color: var(--rs-text-heading);
}
.rs-picker-check-menu-items .rs-picker-menu-group-title:hover {
  background-color: var(--rs-state-hover-bg);
}
.rs-picker-check-menu-items .rs-picker-menu-group-title .rs-picker-menu-group-caret {
  display: inline-block;
  margin-left: 2px;
  position: absolute;
  top: 8px;
  right: 12px;
  transition: transform 0.3s linear;
  padding: 3px;
}
.rs-picker-check-menu-items .rs-picker-menu-group.folded .rs-picker-menu-group-caret {
  transform: rotate(90deg);
}
.rs-picker-check-menu-items .rs-check-item.rs-checkbox-checked .rs-checkbox-checker > label {
  font-weight: bold;
}
.rs-checkbox {
  position: relative;
  display: inline-block;
  vertical-align: middle;
}
.rs-checkbox-disabled label {
  cursor: not-allowed;
}
.rs-checkbox label {
  line-height: 1.14285714;
  margin-bottom: 0;
  font-weight: normal;
  cursor: pointer;
}
.rs-checkbox-inner::before {
  border-color: var(--rs-checkbox-checked-bg);
}
.rs-checkbox.rs-checkbox-disabled label {
  cursor: not-allowed;
}
.rs-checkbox-disabled > .rs-checkbox-checker > label {
  color: var(--rs-text-disabled);
}
.rs-checkbox-inline {
  position: relative;
  display: inline-block;
  margin-bottom: 0;
  vertical-align: middle;
  font-weight: normal;
  margin-top: 0;
  margin-left: 10px;
}
.rs-plaintext .rs-checkbox-inline:first-child {
  margin-left: 0;
}
.rs-checkbox [type='checkbox']:focus-visible ~ .rs-checkbox-inner::before {
  box-shadow: var(--rs-state-focus-shadow);
}
.rs-checkbox-checker {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 36px;
  min-height: 36px;
  line-height: 1;
  position: relative;
}
/* rtl:begin:ignore */
.rs-checkbox-wrapper::before,
.rs-checkbox-wrapper::after,
.rs-checkbox-wrapper .rs-checkbox-inner::before,
.rs-checkbox-wrapper .rs-checkbox-inner::after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  display: block;
}
.rs-checkbox-wrapper .rs-checkbox-inner::after {
  opacity: 0;
  transform: rotate(45deg) scale(0);
  transition: opacity 0.2s ease-in, transform 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46);
}
.rs-theme-high-contrast .rs-checkbox-wrapper .rs-checkbox-inner::after {
  transition: none;
}
.rs-checkbox-checked .rs-checkbox-wrapper .rs-checkbox-inner::after,
.rs-checkbox-indeterminate .rs-checkbox-wrapper .rs-checkbox-inner::after {
  border: solid var(--rs-checkbox-icon);
  width: 6px;
  height: 9px;
  margin-top: 2px;
  margin-left: 5px;
  opacity: 1;
}
.rs-checkbox-checked .rs-checkbox-wrapper .rs-checkbox-inner::after {
  border-width: 0 2px 2px 0;
  transform: rotate(45deg) scale(1);
}
.rs-checkbox-indeterminate .rs-checkbox-wrapper .rs-checkbox-inner::after {
  border-width: 0 0 2px;
  transform: rotate(0deg) scale(1);
  width: 10px;
  margin-top: 0;
  margin-left: 3px;
}
/* rtl:end:ignore */
/* stylelint-disable-next-line */
.rs-checkbox-wrapper {
  position: absolute;
  width: 16px;
  height: 16px;
  display: inline-block;
  left: 10px;
  top: 10px;
}
.rs-checkbox-wrapper [type='checkbox'] {
  width: 0;
  height: 0;
  opacity: 0;
}
.rs-checkbox-wrapper::before,
.rs-checkbox-wrapper .rs-checkbox-inner::before,
.rs-checkbox-wrapper .rs-checkbox-inner::after {
  width: 16px;
  height: 16px;
}
.rs-checkbox-wrapper::before {
  border: 1px solid var(--rs-checkbox-checked-bg);
  background-color: transparent;
  border-radius: 3px;
  transform: scale(1);
  opacity: 0.7;
  visibility: hidden;
  transition: transform 0.2s linear, opacity 0.2s linear;
}
.rs-theme-high-contrast .rs-checkbox-wrapper::before {
  transition: none;
}
.rs-checkbox-checked .rs-checkbox-wrapper::before {
  transform: scale(1.5);
  opacity: 0;
  visibility: visible;
}
.rs-checkbox-wrapper::after {
  top: -10px;
  right: -10px;
  bottom: -10px;
  left: -10px;
}
.rs-checkbox-wrapper .rs-checkbox-inner::before {
  border: 1px solid var(--rs-checkbox-border);
  background-color: transparent;
  border-radius: 3px;
  transition: background 0.2s linear, border 0.2s linear, box-shadow 0.2s linear;
}
.rs-theme-high-contrast .rs-checkbox-wrapper .rs-checkbox-inner::before {
  transition: none;
}
label:hover .rs-checkbox-wrapper .rs-checkbox-inner::before {
  border-color: var(--rs-checkbox-checked-bg);
}
.rs-checkbox-disabled:not(.rs-checkbox-checked):not(.rs-checkbox-indeterminate) .rs-checkbox-wrapper .rs-checkbox-inner::before {
  border-color: var(--rs-checkbox-disabled-bg);
  background-color: var(--rs-checkbox-disabled-bg);
}
.rs-checkbox-checked .rs-checkbox-wrapper .rs-checkbox-inner::before,
.rs-checkbox-indeterminate .rs-checkbox-wrapper .rs-checkbox-inner::before {
  border-color: var(--rs-checkbox-checked-bg);
  background-color: var(--rs-checkbox-checked-bg);
}
.rs-checkbox-disabled.rs-checkbox-checked .rs-checkbox-wrapper .rs-checkbox-inner::before,
.rs-checkbox-disabled.rs-checkbox-indeterminate .rs-checkbox-wrapper .rs-checkbox-inner::before {
  opacity: 0.3;
}
.rs-theme-high-contrast .rs-checkbox-disabled.rs-checkbox-checked .rs-checkbox-wrapper .rs-checkbox-inner::before,
.rs-theme-high-contrast .rs-checkbox-disabled.rs-checkbox-indeterminate .rs-checkbox-wrapper .rs-checkbox-inner::before {
  opacity: 0.5;
}
.rs-check-tree {
  max-height: 360px;
  height: 100%;
  overflow-y: auto;
  flex: 1 1 auto;
}
.rs-check-tree.rs-check-tree-virtualized {
  overflow: hidden;
  /* stylelint-disable-next-line */
}
.rs-check-tree.rs-check-tree-virtualized .ReactVirtualized__Grid.ReactVirtualized__List {
  padding: 0 12px 12px 0;
}
.rs-check-tree.rs-check-tree-virtualized .ReactVirtualized__Grid.ReactVirtualized__List:focus {
  outline: none;
}
.rs-check-tree .rs-check-item .rs-checkbox-checker > label {
  text-align: left;
  position: relative;
  margin: 0;
  padding: 8px 12px;
  padding-left: 50px;
}
.rs-check-tree .rs-check-item .rs-checkbox-checker > label::before {
  content: '';
  position: absolute;
  width: 46px;
  height: 100%;
  top: 0;
  margin-left: -52px;
}
.rs-check-tree .rs-check-item .rs-checkbox-checker .rs-checkbox-wrapper {
  left: 20px;
}
.rs-check-tree-without-children .rs-check-item .rs-checkbox-checker > label {
  padding-left: 32px;
}
.rs-check-tree-without-children .rs-check-item .rs-checkbox-checker > label::before {
  width: 28px;
  margin-left: -34px;
}
.rs-check-tree-without-children .rs-check-item .rs-checkbox-checker .rs-checkbox-wrapper {
  left: 0;
}
.rs-check-tree-node {
  position: relative;
  font-size: 14px;
  line-height: 22px;
}
.rs-check-tree-node .rs-check-item {
  display: inline-block;
}
.rs-picker-menu .rs-check-tree-node .rs-check-item {
  display: block;
}
.rs-check-tree-node:focus .rs-check-item .rs-checkbox-checker > label {
  color: var(--rs-text-primary);
  background-color: var(--rs-state-hover-bg);
}
.rs-check-tree-node .rs-check-item.rs-checkbox-checked .rs-checkbox-checker > label {
  font-weight: bold;
}
.rs-check-tree-node-all-uncheckable > .rs-check-item .rs-checkbox-checker > label {
  padding-left: 22px;
}
.rs-check-tree-node-all-uncheckable > .rs-check-item .rs-checkbox-checker > label::before {
  width: 14px;
  margin-left: 0;
  left: 0;
}
.rs-check-tree-node-expand-icon-wrapper {
  display: inline-block;
  position: absolute;
  cursor: pointer;
  z-index: 1;
}
.rs-check-tree-node-expand-icon-wrapper > .rs-check-tree-node-expand-icon {
  display: inline-block;
  padding: 8px 0;
  padding-right: 8px;
  height: 36px;
  font-size: 16px;
  line-height: 22px;
  transform: rotate(-90deg) /* rtl: rotate(90deg) translateX(-13px) translateY(-15px) */;
  transform-origin: 1.5px 14px;
  margin-top: 6px;
  user-select: none;
}
.rs-check-tree-open > .rs-check-tree-node .rs-check-tree-node-expand-icon-wrapper > .rs-check-tree-node-expand-icon {
  transform: rotate(0deg);
  margin-left: 0;
  margin-top: 0;
}
.rs-check-tree-node-expand-icon-wrapper > .rs-check-tree-node-expanded {
  transform: rotate(0deg);
  margin-left: 0;
  margin-top: 0;
}
.rs-check-tree-node-expand-icon-wrapper > .rs-check-tree-node-custom-icon,
.rs-check-tree-node-expand-icon-wrapper > .rs-check-tree-node-loading-icon {
  width: 14px;
  height: 36px;
  display: flex;
  align-items: center;
  font-size: 14px;
}
.rs-check-tree-node-children > .rs-check-tree-children {
  position: relative;
  display: none;
}
.rs-check-tree-open.rs-check-tree-node-children > .rs-check-tree-children {
  display: block;
}
.rs-check-tree-indent-line {
  height: 100%;
  width: 1px;
  position: absolute;
  top: 0;
  margin-left: -13px;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
}
.rs-picker-menu {
  display: flex;
  flex-direction: column;
}
.rs-picker-menu.rs-check-tree-menu {
  padding-top: 12px;
}
.rs-picker-menu.rs-check-tree-menu .rs-picker-search-bar {
  padding-top: 0;
}
.rs-picker-menu .rs-check-tree {
  padding: 0 12px 12px 0;
}
.rs-picker-menu .rs-check-tree.rs-check-tree-virtualized {
  padding: 0;
}
.rs-picker-menu .rs-check-tree-node > .rs-check-tree-node-label .rs-check-tree-node-text-wrapper {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  display: inline-block;
  vertical-align: top;
}
/* rtl:begin:ignore */
[dir='rtl'] .rs-check-tree-node-expand-icon-wrapper {
  right: 0;
  padding-right: inherit;
}
/* stylelint-disable-next-line */
.rs-check-tree {
  /* stylelint-disable-next-line */
}
.rs-check-tree-node > .rs-check-tree-node-label .rs-check-item .rs-checkbox-checker > label::before {
  /* stylelint-disable-next-line */
}
[dir='rtl'] .ReactVirtualized__Grid__innerScrollContainer .rs-check-tree-node > .rs-check-tree-node-label .rs-check-item .rs-checkbox-checker > label::before {
  padding-right: inherit;
  right: 46px;
}
/* rtl:end:ignore */
.rs-btn-red {
  --rs-btn-primary-bg: var(--rs-red-500);
  --rs-btn-primary-hover-bg: var(--rs-red-600);
  --rs-btn-primary-active-bg: var(--rs-red-700);
  --rs-btn-subtle-hover-bg: var(--rs-red-500);
  --rs-btn-subtle-hover-text: #fff;
  --rs-btn-subtle-active-bg: var(--rs-red-600);
  --rs-btn-subtle-active-text: #fff;
  --rs-btn-ghost-border: var(--rs-red-700);
  --rs-btn-ghost-text: var(--rs-red-700);
  --rs-btn-ghost-hover-border: var(--rs-red-800);
  --rs-btn-ghost-hover-text: var(--rs-red-800);
  --rs-btn-ghost-active-border: var(--rs-red-900);
  --rs-btn-ghost-active-text: var(--rs-red-900);
  --rs-btn-link-text: var(--rs-red-700);
  --rs-btn-link-hover-text: var(--rs-red-800);
  --rs-btn-link-active-text: var(--rs-red-900);
  --rs-iconbtn-primary-addon: var(--rs-red-600);
  --rs-iconbtn-primary-activated-addon: var(--rs-red-700);
  --rs-iconbtn-primary-pressed-addon: var(--rs-red-800);
}
.rs-theme-dark .rs-btn-red {
  --rs-btn-primary-bg: var(--rs-red-700);
  --rs-btn-primary-hover-bg: var(--rs-red-600);
  --rs-btn-primary-active-bg: var(--rs-red-400);
  --rs-btn-subtle-hover-bg: var(--rs-red-600);
  --rs-btn-subtle-hover-text: #fff;
  --rs-btn-subtle-active-bg: var(--rs-red-400);
  --rs-btn-subtle-active-text: #fff;
  --rs-btn-ghost-border: var(--rs-red-500);
  --rs-btn-ghost-text: var(--rs-red-500);
  --rs-btn-ghost-hover-border: var(--rs-red-400);
  --rs-btn-ghost-hover-text: var(--rs-red-400);
  --rs-btn-ghost-active-border: var(--rs-red-200);
  --rs-btn-ghost-active-text: var(--rs-red-200);
  --rs-btn-link-text: var(--rs-red-500);
  --rs-btn-link-hover-text: var(--rs-red-400);
  --rs-btn-link-active-text: var(--rs-red-200);
  --rs-iconbtn-primary-addon: var(--rs-red-600);
  --rs-iconbtn-primary-activated-addon: var(--rs-red-500);
  --rs-iconbtn-primary-pressed-addon: var(--rs-red-400);
}
.rs-theme-high-contrast .rs-btn-red {
  --rs-btn-primary-bg: var(--rs-red-700);
  --rs-btn-primary-hover-bg: var(--rs-red-600);
  --rs-btn-primary-active-bg: var(--rs-red-400);
  --rs-btn-subtle-hover-bg: var(--rs-red-600);
  --rs-btn-subtle-hover-text: var(--rs-gray-900);
  --rs-btn-subtle-active-bg: var(--rs-red-400);
  --rs-btn-subtle-active-text: var(--rs-gray-900);
  --rs-btn-ghost-border: var(--rs-red-500);
  --rs-btn-ghost-text: var(--rs-red-500);
  --rs-btn-ghost-hover-border: var(--rs-red-400);
  --rs-btn-ghost-hover-text: var(--rs-red-400);
  --rs-btn-ghost-active-border: var(--rs-red-200);
  --rs-btn-ghost-active-text: var(--rs-red-200);
  --rs-btn-link-text: var(--rs-red-500);
  --rs-btn-link-hover-text: var(--rs-red-400);
  --rs-btn-link-active-text: var(--rs-red-200);
}
.rs-btn-orange {
  --rs-btn-primary-bg: var(--rs-orange-500);
  --rs-btn-primary-hover-bg: var(--rs-orange-600);
  --rs-btn-primary-active-bg: var(--rs-orange-700);
  --rs-btn-subtle-hover-bg: var(--rs-orange-500);
  --rs-btn-subtle-hover-text: #fff;
  --rs-btn-subtle-active-bg: var(--rs-orange-600);
  --rs-btn-subtle-active-text: #fff;
  --rs-btn-ghost-border: var(--rs-orange-700);
  --rs-btn-ghost-text: var(--rs-orange-700);
  --rs-btn-ghost-hover-border: var(--rs-orange-800);
  --rs-btn-ghost-hover-text: var(--rs-orange-800);
  --rs-btn-ghost-active-border: var(--rs-orange-900);
  --rs-btn-ghost-active-text: var(--rs-orange-900);
  --rs-btn-link-text: var(--rs-orange-700);
  --rs-btn-link-hover-text: var(--rs-orange-800);
  --rs-btn-link-active-text: var(--rs-orange-900);
  --rs-iconbtn-primary-addon: var(--rs-orange-600);
  --rs-iconbtn-primary-activated-addon: var(--rs-orange-700);
  --rs-iconbtn-primary-pressed-addon: var(--rs-orange-800);
}
.rs-theme-dark .rs-btn-orange {
  --rs-btn-primary-bg: var(--rs-orange-700);
  --rs-btn-primary-hover-bg: var(--rs-orange-600);
  --rs-btn-primary-active-bg: var(--rs-orange-400);
  --rs-btn-subtle-hover-bg: var(--rs-orange-600);
  --rs-btn-subtle-hover-text: #fff;
  --rs-btn-subtle-active-bg: var(--rs-orange-400);
  --rs-btn-subtle-active-text: #fff;
  --rs-btn-ghost-border: var(--rs-orange-500);
  --rs-btn-ghost-text: var(--rs-orange-500);
  --rs-btn-ghost-hover-border: var(--rs-orange-400);
  --rs-btn-ghost-hover-text: var(--rs-orange-400);
  --rs-btn-ghost-active-border: var(--rs-orange-200);
  --rs-btn-ghost-active-text: var(--rs-orange-200);
  --rs-btn-link-text: var(--rs-orange-500);
  --rs-btn-link-hover-text: var(--rs-orange-400);
  --rs-btn-link-active-text: var(--rs-orange-200);
  --rs-iconbtn-primary-addon: var(--rs-orange-600);
  --rs-iconbtn-primary-activated-addon: var(--rs-orange-500);
  --rs-iconbtn-primary-pressed-addon: var(--rs-orange-400);
}
.rs-theme-high-contrast .rs-btn-orange {
  --rs-btn-primary-bg: var(--rs-orange-700);
  --rs-btn-primary-hover-bg: var(--rs-orange-600);
  --rs-btn-primary-active-bg: var(--rs-orange-400);
  --rs-btn-subtle-hover-bg: var(--rs-orange-600);
  --rs-btn-subtle-hover-text: var(--rs-gray-900);
  --rs-btn-subtle-active-bg: var(--rs-orange-400);
  --rs-btn-subtle-active-text: var(--rs-gray-900);
  --rs-btn-ghost-border: var(--rs-orange-500);
  --rs-btn-ghost-text: var(--rs-orange-500);
  --rs-btn-ghost-hover-border: var(--rs-orange-400);
  --rs-btn-ghost-hover-text: var(--rs-orange-400);
  --rs-btn-ghost-active-border: var(--rs-orange-200);
  --rs-btn-ghost-active-text: var(--rs-orange-200);
  --rs-btn-link-text: var(--rs-orange-500);
  --rs-btn-link-hover-text: var(--rs-orange-400);
  --rs-btn-link-active-text: var(--rs-orange-200);
}
.rs-btn-yellow {
  --rs-btn-primary-bg: var(--rs-yellow-500);
  --rs-btn-primary-hover-bg: var(--rs-yellow-600);
  --rs-btn-primary-active-bg: var(--rs-yellow-700);
  --rs-btn-subtle-hover-bg: var(--rs-yellow-500);
  --rs-btn-subtle-hover-text: #fff;
  --rs-btn-subtle-active-bg: var(--rs-yellow-600);
  --rs-btn-subtle-active-text: #fff;
  --rs-btn-ghost-border: var(--rs-yellow-700);
  --rs-btn-ghost-text: var(--rs-yellow-700);
  --rs-btn-ghost-hover-border: var(--rs-yellow-800);
  --rs-btn-ghost-hover-text: var(--rs-yellow-800);
  --rs-btn-ghost-active-border: var(--rs-yellow-900);
  --rs-btn-ghost-active-text: var(--rs-yellow-900);
  --rs-btn-link-text: var(--rs-yellow-700);
  --rs-btn-link-hover-text: var(--rs-yellow-800);
  --rs-btn-link-active-text: var(--rs-yellow-900);
  --rs-iconbtn-primary-addon: var(--rs-yellow-600);
  --rs-iconbtn-primary-activated-addon: var(--rs-yellow-700);
  --rs-iconbtn-primary-pressed-addon: var(--rs-yellow-800);
}
.rs-theme-dark .rs-btn-yellow {
  --rs-btn-primary-bg: var(--rs-yellow-700);
  --rs-btn-primary-hover-bg: var(--rs-yellow-600);
  --rs-btn-primary-active-bg: var(--rs-yellow-400);
  --rs-btn-subtle-hover-bg: var(--rs-yellow-600);
  --rs-btn-subtle-hover-text: #fff;
  --rs-btn-subtle-active-bg: var(--rs-yellow-400);
  --rs-btn-subtle-active-text: #fff;
  --rs-btn-ghost-border: var(--rs-yellow-500);
  --rs-btn-ghost-text: var(--rs-yellow-500);
  --rs-btn-ghost-hover-border: var(--rs-yellow-400);
  --rs-btn-ghost-hover-text: var(--rs-yellow-400);
  --rs-btn-ghost-active-border: var(--rs-yellow-200);
  --rs-btn-ghost-active-text: var(--rs-yellow-200);
  --rs-btn-link-text: var(--rs-yellow-500);
  --rs-btn-link-hover-text: var(--rs-yellow-400);
  --rs-btn-link-active-text: var(--rs-yellow-200);
  --rs-iconbtn-primary-addon: var(--rs-yellow-600);
  --rs-iconbtn-primary-activated-addon: var(--rs-yellow-500);
  --rs-iconbtn-primary-pressed-addon: var(--rs-yellow-400);
}
.rs-theme-high-contrast .rs-btn-yellow {
  --rs-btn-primary-bg: var(--rs-yellow-700);
  --rs-btn-primary-hover-bg: var(--rs-yellow-600);
  --rs-btn-primary-active-bg: var(--rs-yellow-400);
  --rs-btn-subtle-hover-bg: var(--rs-yellow-600);
  --rs-btn-subtle-hover-text: var(--rs-gray-900);
  --rs-btn-subtle-active-bg: var(--rs-yellow-400);
  --rs-btn-subtle-active-text: var(--rs-gray-900);
  --rs-btn-ghost-border: var(--rs-yellow-500);
  --rs-btn-ghost-text: var(--rs-yellow-500);
  --rs-btn-ghost-hover-border: var(--rs-yellow-400);
  --rs-btn-ghost-hover-text: var(--rs-yellow-400);
  --rs-btn-ghost-active-border: var(--rs-yellow-200);
  --rs-btn-ghost-active-text: var(--rs-yellow-200);
  --rs-btn-link-text: var(--rs-yellow-500);
  --rs-btn-link-hover-text: var(--rs-yellow-400);
  --rs-btn-link-active-text: var(--rs-yellow-200);
}
.rs-btn-green {
  --rs-btn-primary-bg: var(--rs-green-500);
  --rs-btn-primary-hover-bg: var(--rs-green-600);
  --rs-btn-primary-active-bg: var(--rs-green-700);
  --rs-btn-subtle-hover-bg: var(--rs-green-500);
  --rs-btn-subtle-hover-text: #fff;
  --rs-btn-subtle-active-bg: var(--rs-green-600);
  --rs-btn-subtle-active-text: #fff;
  --rs-btn-ghost-border: var(--rs-green-700);
  --rs-btn-ghost-text: var(--rs-green-700);
  --rs-btn-ghost-hover-border: var(--rs-green-800);
  --rs-btn-ghost-hover-text: var(--rs-green-800);
  --rs-btn-ghost-active-border: var(--rs-green-900);
  --rs-btn-ghost-active-text: var(--rs-green-900);
  --rs-btn-link-text: var(--rs-green-700);
  --rs-btn-link-hover-text: var(--rs-green-800);
  --rs-btn-link-active-text: var(--rs-green-900);
  --rs-iconbtn-primary-addon: var(--rs-green-600);
  --rs-iconbtn-primary-activated-addon: var(--rs-green-700);
  --rs-iconbtn-primary-pressed-addon: var(--rs-green-800);
}
.rs-theme-dark .rs-btn-green {
  --rs-btn-primary-bg: var(--rs-green-700);
  --rs-btn-primary-hover-bg: var(--rs-green-600);
  --rs-btn-primary-active-bg: var(--rs-green-400);
  --rs-btn-subtle-hover-bg: var(--rs-green-600);
  --rs-btn-subtle-hover-text: #fff;
  --rs-btn-subtle-active-bg: var(--rs-green-400);
  --rs-btn-subtle-active-text: #fff;
  --rs-btn-ghost-border: var(--rs-green-500);
  --rs-btn-ghost-text: var(--rs-green-500);
  --rs-btn-ghost-hover-border: var(--rs-green-400);
  --rs-btn-ghost-hover-text: var(--rs-green-400);
  --rs-btn-ghost-active-border: var(--rs-green-200);
  --rs-btn-ghost-active-text: var(--rs-green-200);
  --rs-btn-link-text: var(--rs-green-500);
  --rs-btn-link-hover-text: var(--rs-green-400);
  --rs-btn-link-active-text: var(--rs-green-200);
  --rs-iconbtn-primary-addon: var(--rs-green-600);
  --rs-iconbtn-primary-activated-addon: var(--rs-green-500);
  --rs-iconbtn-primary-pressed-addon: var(--rs-green-400);
}
.rs-theme-high-contrast .rs-btn-green {
  --rs-btn-primary-bg: var(--rs-green-700);
  --rs-btn-primary-hover-bg: var(--rs-green-600);
  --rs-btn-primary-active-bg: var(--rs-green-400);
  --rs-btn-subtle-hover-bg: var(--rs-green-600);
  --rs-btn-subtle-hover-text: var(--rs-gray-900);
  --rs-btn-subtle-active-bg: var(--rs-green-400);
  --rs-btn-subtle-active-text: var(--rs-gray-900);
  --rs-btn-ghost-border: var(--rs-green-500);
  --rs-btn-ghost-text: var(--rs-green-500);
  --rs-btn-ghost-hover-border: var(--rs-green-400);
  --rs-btn-ghost-hover-text: var(--rs-green-400);
  --rs-btn-ghost-active-border: var(--rs-green-200);
  --rs-btn-ghost-active-text: var(--rs-green-200);
  --rs-btn-link-text: var(--rs-green-500);
  --rs-btn-link-hover-text: var(--rs-green-400);
  --rs-btn-link-active-text: var(--rs-green-200);
}
.rs-btn-cyan {
  --rs-btn-primary-bg: var(--rs-cyan-500);
  --rs-btn-primary-hover-bg: var(--rs-cyan-600);
  --rs-btn-primary-active-bg: var(--rs-cyan-700);
  --rs-btn-subtle-hover-bg: var(--rs-cyan-500);
  --rs-btn-subtle-hover-text: #fff;
  --rs-btn-subtle-active-bg: var(--rs-cyan-600);
  --rs-btn-subtle-active-text: #fff;
  --rs-btn-ghost-border: var(--rs-cyan-700);
  --rs-btn-ghost-text: var(--rs-cyan-700);
  --rs-btn-ghost-hover-border: var(--rs-cyan-800);
  --rs-btn-ghost-hover-text: var(--rs-cyan-800);
  --rs-btn-ghost-active-border: var(--rs-cyan-900);
  --rs-btn-ghost-active-text: var(--rs-cyan-900);
  --rs-btn-link-text: var(--rs-cyan-700);
  --rs-btn-link-hover-text: var(--rs-cyan-800);
  --rs-btn-link-active-text: var(--rs-cyan-900);
  --rs-iconbtn-primary-addon: var(--rs-cyan-600);
  --rs-iconbtn-primary-activated-addon: var(--rs-cyan-700);
  --rs-iconbtn-primary-pressed-addon: var(--rs-cyan-800);
}
.rs-theme-dark .rs-btn-cyan {
  --rs-btn-primary-bg: var(--rs-cyan-700);
  --rs-btn-primary-hover-bg: var(--rs-cyan-600);
  --rs-btn-primary-active-bg: var(--rs-cyan-400);
  --rs-btn-subtle-hover-bg: var(--rs-cyan-600);
  --rs-btn-subtle-hover-text: #fff;
  --rs-btn-subtle-active-bg: var(--rs-cyan-400);
  --rs-btn-subtle-active-text: #fff;
  --rs-btn-ghost-border: var(--rs-cyan-500);
  --rs-btn-ghost-text: var(--rs-cyan-500);
  --rs-btn-ghost-hover-border: var(--rs-cyan-400);
  --rs-btn-ghost-hover-text: var(--rs-cyan-400);
  --rs-btn-ghost-active-border: var(--rs-cyan-200);
  --rs-btn-ghost-active-text: var(--rs-cyan-200);
  --rs-btn-link-text: var(--rs-cyan-500);
  --rs-btn-link-hover-text: var(--rs-cyan-400);
  --rs-btn-link-active-text: var(--rs-cyan-200);
  --rs-iconbtn-primary-addon: var(--rs-cyan-600);
  --rs-iconbtn-primary-activated-addon: var(--rs-cyan-500);
  --rs-iconbtn-primary-pressed-addon: var(--rs-cyan-400);
}
.rs-theme-high-contrast .rs-btn-cyan {
  --rs-btn-primary-bg: var(--rs-cyan-700);
  --rs-btn-primary-hover-bg: var(--rs-cyan-600);
  --rs-btn-primary-active-bg: var(--rs-cyan-400);
  --rs-btn-subtle-hover-bg: var(--rs-cyan-600);
  --rs-btn-subtle-hover-text: var(--rs-gray-900);
  --rs-btn-subtle-active-bg: var(--rs-cyan-400);
  --rs-btn-subtle-active-text: var(--rs-gray-900);
  --rs-btn-ghost-border: var(--rs-cyan-500);
  --rs-btn-ghost-text: var(--rs-cyan-500);
  --rs-btn-ghost-hover-border: var(--rs-cyan-400);
  --rs-btn-ghost-hover-text: var(--rs-cyan-400);
  --rs-btn-ghost-active-border: var(--rs-cyan-200);
  --rs-btn-ghost-active-text: var(--rs-cyan-200);
  --rs-btn-link-text: var(--rs-cyan-500);
  --rs-btn-link-hover-text: var(--rs-cyan-400);
  --rs-btn-link-active-text: var(--rs-cyan-200);
}
.rs-btn-blue {
  --rs-btn-primary-bg: var(--rs-blue-500);
  --rs-btn-primary-hover-bg: var(--rs-blue-600);
  --rs-btn-primary-active-bg: var(--rs-blue-700);
  --rs-btn-subtle-hover-bg: var(--rs-blue-500);
  --rs-btn-subtle-hover-text: #fff;
  --rs-btn-subtle-active-bg: var(--rs-blue-600);
  --rs-btn-subtle-active-text: #fff;
  --rs-btn-ghost-border: var(--rs-blue-700);
  --rs-btn-ghost-text: var(--rs-blue-700);
  --rs-btn-ghost-hover-border: var(--rs-blue-800);
  --rs-btn-ghost-hover-text: var(--rs-blue-800);
  --rs-btn-ghost-active-border: var(--rs-blue-900);
  --rs-btn-ghost-active-text: var(--rs-blue-900);
  --rs-btn-link-text: var(--rs-blue-700);
  --rs-btn-link-hover-text: var(--rs-blue-800);
  --rs-btn-link-active-text: var(--rs-blue-900);
  --rs-iconbtn-primary-addon: var(--rs-blue-600);
  --rs-iconbtn-primary-activated-addon: var(--rs-blue-700);
  --rs-iconbtn-primary-pressed-addon: var(--rs-blue-800);
}
.rs-theme-dark .rs-btn-blue {
  --rs-btn-primary-bg: var(--rs-blue-700);
  --rs-btn-primary-hover-bg: var(--rs-blue-600);
  --rs-btn-primary-active-bg: var(--rs-blue-400);
  --rs-btn-subtle-hover-bg: var(--rs-blue-600);
  --rs-btn-subtle-hover-text: #fff;
  --rs-btn-subtle-active-bg: var(--rs-blue-400);
  --rs-btn-subtle-active-text: #fff;
  --rs-btn-ghost-border: var(--rs-blue-500);
  --rs-btn-ghost-text: var(--rs-blue-500);
  --rs-btn-ghost-hover-border: var(--rs-blue-400);
  --rs-btn-ghost-hover-text: var(--rs-blue-400);
  --rs-btn-ghost-active-border: var(--rs-blue-200);
  --rs-btn-ghost-active-text: var(--rs-blue-200);
  --rs-btn-link-text: var(--rs-blue-500);
  --rs-btn-link-hover-text: var(--rs-blue-400);
  --rs-btn-link-active-text: var(--rs-blue-200);
  --rs-iconbtn-primary-addon: var(--rs-blue-600);
  --rs-iconbtn-primary-activated-addon: var(--rs-blue-500);
  --rs-iconbtn-primary-pressed-addon: var(--rs-blue-400);
}
.rs-theme-high-contrast .rs-btn-blue {
  --rs-btn-primary-bg: var(--rs-blue-700);
  --rs-btn-primary-hover-bg: var(--rs-blue-600);
  --rs-btn-primary-active-bg: var(--rs-blue-400);
  --rs-btn-subtle-hover-bg: var(--rs-blue-600);
  --rs-btn-subtle-hover-text: var(--rs-gray-900);
  --rs-btn-subtle-active-bg: var(--rs-blue-400);
  --rs-btn-subtle-active-text: var(--rs-gray-900);
  --rs-btn-ghost-border: var(--rs-blue-500);
  --rs-btn-ghost-text: var(--rs-blue-500);
  --rs-btn-ghost-hover-border: var(--rs-blue-400);
  --rs-btn-ghost-hover-text: var(--rs-blue-400);
  --rs-btn-ghost-active-border: var(--rs-blue-200);
  --rs-btn-ghost-active-text: var(--rs-blue-200);
  --rs-btn-link-text: var(--rs-blue-500);
  --rs-btn-link-hover-text: var(--rs-blue-400);
  --rs-btn-link-active-text: var(--rs-blue-200);
}
.rs-btn-violet {
  --rs-btn-primary-bg: var(--rs-violet-500);
  --rs-btn-primary-hover-bg: var(--rs-violet-600);
  --rs-btn-primary-active-bg: var(--rs-violet-700);
  --rs-btn-subtle-hover-bg: var(--rs-violet-500);
  --rs-btn-subtle-hover-text: #fff;
  --rs-btn-subtle-active-bg: var(--rs-violet-600);
  --rs-btn-subtle-active-text: #fff;
  --rs-btn-ghost-border: var(--rs-violet-700);
  --rs-btn-ghost-text: var(--rs-violet-700);
  --rs-btn-ghost-hover-border: var(--rs-violet-800);
  --rs-btn-ghost-hover-text: var(--rs-violet-800);
  --rs-btn-ghost-active-border: var(--rs-violet-900);
  --rs-btn-ghost-active-text: var(--rs-violet-900);
  --rs-btn-link-text: var(--rs-violet-700);
  --rs-btn-link-hover-text: var(--rs-violet-800);
  --rs-btn-link-active-text: var(--rs-violet-900);
  --rs-iconbtn-primary-addon: var(--rs-violet-600);
  --rs-iconbtn-primary-activated-addon: var(--rs-violet-700);
  --rs-iconbtn-primary-pressed-addon: var(--rs-violet-800);
}
.rs-theme-dark .rs-btn-violet {
  --rs-btn-primary-bg: var(--rs-violet-700);
  --rs-btn-primary-hover-bg: var(--rs-violet-600);
  --rs-btn-primary-active-bg: var(--rs-violet-400);
  --rs-btn-subtle-hover-bg: var(--rs-violet-600);
  --rs-btn-subtle-hover-text: #fff;
  --rs-btn-subtle-active-bg: var(--rs-violet-400);
  --rs-btn-subtle-active-text: #fff;
  --rs-btn-ghost-border: var(--rs-violet-500);
  --rs-btn-ghost-text: var(--rs-violet-500);
  --rs-btn-ghost-hover-border: var(--rs-violet-400);
  --rs-btn-ghost-hover-text: var(--rs-violet-400);
  --rs-btn-ghost-active-border: var(--rs-violet-200);
  --rs-btn-ghost-active-text: var(--rs-violet-200);
  --rs-btn-link-text: var(--rs-violet-500);
  --rs-btn-link-hover-text: var(--rs-violet-400);
  --rs-btn-link-active-text: var(--rs-violet-200);
  --rs-iconbtn-primary-addon: var(--rs-violet-600);
  --rs-iconbtn-primary-activated-addon: var(--rs-violet-500);
  --rs-iconbtn-primary-pressed-addon: var(--rs-violet-400);
}
.rs-theme-high-contrast .rs-btn-violet {
  --rs-btn-primary-bg: var(--rs-violet-700);
  --rs-btn-primary-hover-bg: var(--rs-violet-600);
  --rs-btn-primary-active-bg: var(--rs-violet-400);
  --rs-btn-subtle-hover-bg: var(--rs-violet-600);
  --rs-btn-subtle-hover-text: var(--rs-gray-900);
  --rs-btn-subtle-active-bg: var(--rs-violet-400);
  --rs-btn-subtle-active-text: var(--rs-gray-900);
  --rs-btn-ghost-border: var(--rs-violet-500);
  --rs-btn-ghost-text: var(--rs-violet-500);
  --rs-btn-ghost-hover-border: var(--rs-violet-400);
  --rs-btn-ghost-hover-text: var(--rs-violet-400);
  --rs-btn-ghost-active-border: var(--rs-violet-200);
  --rs-btn-ghost-active-text: var(--rs-violet-200);
  --rs-btn-link-text: var(--rs-violet-500);
  --rs-btn-link-hover-text: var(--rs-violet-400);
  --rs-btn-link-active-text: var(--rs-violet-200);
}
.rs-picker-subtle .picker-subtle-toggle {
  position: relative;
  z-index: 5;
  padding-right: 32px;
  display: inline-block;
  cursor: pointer;
}
.rs-picker-subtle.rs-picker-disabled .picker-subtle-toggle {
  cursor: not-allowed;
}
.rs-picker-subtle.rs-picker-disabled .picker-subtle-toggle:hover,
.rs-picker-subtle.rs-picker-disabled .picker-subtle-toggle:focus,
.rs-picker-subtle.rs-picker-disabled .picker-subtle-toggle:active {
  background: none;
}
.rs-picker-subtle.rs-picker-disabled .picker-subtle-toggle:hover::after,
.rs-picker-subtle.rs-picker-disabled .picker-subtle-toggle:focus::after,
.rs-picker-subtle.rs-picker-disabled .picker-subtle-toggle:active::after {
  display: none;
}
.rs-picker-toggle-wrapper {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
}
.rs-picker-toggle.rs-btn .rs-ripple-pond {
  display: none !important;
}
.rs-picker-block {
  display: block;
}
.rs-picker-disabled {
  opacity: 0.3;
}
.rs-picker-toggle .rs-picker-toggle-placeholder {
  color: var(--rs-text-secondary);
}
.rs-picker-has-value .rs-btn .rs-picker-toggle-value,
.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
  color: var(--rs-picker-value);
}
.rs-picker-none {
  padding: 6px 12px 12px;
  color: var(--rs-text-secondary);
  cursor: default;
}
.rs-picker-countable .rs-picker-toggle-value {
  display: flex;
}
.rs-picker-value-list {
  flex: 0 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .rs-picker-value-list {
    max-width: 100%;
  }
}
.rs-picker-value-count {
  margin: 0 4px;
  background-color: var(--rs-picker-count-bg);
  color: var(--rs-picker-count-text);
  border-radius: 10px;
  padding: 0 8px;
  line-height: 20px;
}
.rs-picker-value-separator {
  margin: 0 4px 0 0;
}
.rs-picker:not(.rs-picker-disabled):hover,
.rs-picker.rs-picker-focused {
  border-color: var(--rs-input-focus-border);
}
.rs-picker-toggle-active,
.rs-picker.rs-picker-focused {
  box-shadow: var(--rs-state-focus-shadow);
}
.rs-picker-toggle {
  display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  white-space: nowrap;
  transition: color 0.2s linear, background-color 0.3s linear;
  border: var(--rs-btn-default-border, none);
  user-select: none;
  text-decoration: none;
  color: var(--rs-btn-default-text);
  background-color: var(--rs-btn-default-bg);
  border-radius: 6px;
  font-size: 14px;
  line-height: 20px;
  padding: 8px 12px;
  overflow: hidden;
  position: relative;
}
.rs-theme-high-contrast .rs-picker-toggle {
  transition: none;
}
.rs-btn-ghost.rs-picker-toggle {
  padding: 7px 11px;
}
.rs-btn-icon.rs-picker-toggle {
  padding: 10px 10px;
  line-height: 16px;
}
.rs-btn-icon.rs-picker-toggle > .rs-icon {
  font-size: 16px;
}
.rs-btn-icon-with-text.rs-picker-toggle {
  line-height: 20px;
}
.rs-btn-icon-with-text.rs-picker-toggle > .rs-icon {
  padding: 10px 10px;
  width: 36px;
  height: 36px;
}
.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-icon-placement-left {
  padding: 8px 12px 8px 48px;
}
.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-icon-placement-left > .rs-icon {
  left: 0;
  border-right: var(--rs-btn-default-border, none);
}
.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-icon-placement-right {
  padding: 8px 48px 8px 12px;
}
.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-icon-placement-right > .rs-icon {
  right: 0;
  border-left: var(--rs-btn-default-border, none);
}
.rs-picker-toggle:focus-visible {
  outline: 3px solid var(--rs-color-focus-ring);
}
.rs-theme-high-contrast .rs-picker-toggle:focus-visible {
  outline-offset: 2px;
}
.rs-picker-toggle:hover,
.rs-picker-toggle:focus {
  color: var(--rs-btn-default-hover-text);
  background-color: var(--rs-btn-default-hover-bg);
  text-decoration: none;
}
.rs-picker-toggle:active,
.rs-picker-toggle.rs-btn-active {
  color: var(--rs-btn-default-active-text);
  background-color: var(--rs-btn-default-active-bg);
}
.rs-picker-toggle:disabled,
.rs-picker-toggle.rs-btn-disabled {
  cursor: not-allowed;
  color: var(--rs-btn-default-disabled-text);
  background-color: var(--rs-btn-default-disabled-bg);
}
.rs-theme-high-contrast .rs-picker-toggle:disabled,
.rs-theme-high-contrast .rs-picker-toggle.rs-btn-disabled {
  opacity: 0.5;
}
@media not all and (min-resolution: 0.001dpcm) {
  .rs-picker-toggle {
    /* stylelint-disable */
    -webkit-mask-image: -webkit-radial-gradient(white, black);
    /* stylelint-enable */
  }
}
.rs-picker-default .rs-picker-toggle {
  transition: border-color ease-in-out 0.3s;
}
.rs-theme-high-contrast .rs-picker-default .rs-picker-toggle {
  transition: none;
}
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:hover,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:focus,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle-active {
  border-color: var(--rs-input-focus-border);
}
.rs-picker-subtle .rs-picker-toggle {
  color: var(--rs-btn-subtle-text);
  background-color: transparent;
  border: none;
  transition: none;
}
.rs-picker-subtle .rs-picker-toggle:hover,
.rs-picker-subtle .rs-picker-toggle:focus {
  color: var(--rs-btn-subtle-hover-text);
  background-color: var(--rs-btn-subtle-hover-bg);
}
.rs-picker-subtle .rs-picker-toggle:active,
.rs-picker-subtle .rs-picker-toggle.rs-btn-active {
  color: var(--rs-btn-subtle-active-text);
  background-color: var(--rs-btn-subtle-active-bg);
}
.rs-picker-subtle .rs-picker-toggle:disabled,
.rs-picker-subtle .rs-picker-toggle.rs-btn-disabled {
  color: var(--rs-btn-subtle-disabled-text);
  background: none;
}
.rs-theme-high-contrast .rs-picker-subtle .rs-picker-toggle:disabled,
.rs-theme-high-contrast .rs-picker-subtle .rs-picker-toggle.rs-btn-disabled {
  opacity: 0.5;
}
.rs-picker-subtle .rs-picker-toggle-active {
  background-color: var(--rs-btn-subtle-hover-bg);
  color: var(--rs-btn-subtle-hover-text);
}
.rs-picker-default .rs-picker-toggle {
  padding: 7px 11px;
}
.rs-picker-toggle-textbox {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border: 1px solid #0000;
  padding-left: 10px;
  padding-right: 32px;
  color: var(--rs-text-primary);
  background-color: var(--rs-input-bg);
  outline: none;
}
.rs-picker-toggle.rs-btn-lg .rs-picker-toggle-textbox {
  padding-left: 14px;
}
.rs-picker-toggle.rs-btn-sm .rs-picker-toggle-textbox {
  padding-left: 8px;
}
.rs-picker-toggle.rs-btn-xs .rs-picker-toggle-textbox {
  padding-left: 6px;
}
.rs-picker-toggle-read-only {
  opacity: 0;
}
.rs-picker-default .rs-btn,
.rs-picker-default .rs-picker-toggle {
  transition: border-color ease-in-out 0.3s;
}
.rs-theme-high-contrast .rs-picker-default .rs-btn,
.rs-theme-high-contrast .rs-picker-default .rs-picker-toggle {
  transition: none;
}
.rs-picker-default:not(.rs-picker-disabled) .rs-btn:hover,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:hover,
.rs-picker-default:not(.rs-picker-disabled) .rs-btn:focus,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:focus,
.rs-picker-default:not(.rs-picker-disabled) .rs-btn-active,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle-active {
  border-color: var(--rs-input-focus-border);
}
.rs-picker-subtle .rs-btn,
.rs-picker-subtle .rs-picker-toggle {
  color: var(--rs-btn-subtle-text);
  background-color: transparent;
  border: none;
  transition: none;
}
.rs-picker-subtle .rs-btn:hover,
.rs-picker-subtle .rs-picker-toggle:hover,
.rs-picker-subtle .rs-btn:focus,
.rs-picker-subtle .rs-picker-toggle:focus {
  color: var(--rs-btn-subtle-hover-text);
  background-color: var(--rs-btn-subtle-hover-bg);
}
.rs-picker-subtle .rs-btn:active,
.rs-picker-subtle .rs-picker-toggle:active,
.rs-picker-subtle .rs-btn.rs-btn-active,
.rs-picker-subtle .rs-picker-toggle.rs-btn-active {
  color: var(--rs-btn-subtle-active-text);
  background-color: var(--rs-btn-subtle-active-bg);
}
.rs-picker-subtle .rs-btn:disabled,
.rs-picker-subtle .rs-picker-toggle:disabled,
.rs-picker-subtle .rs-btn.rs-btn-disabled,
.rs-picker-subtle .rs-picker-toggle.rs-btn-disabled {
  color: var(--rs-btn-subtle-disabled-text);
  background: none;
}
.rs-theme-high-contrast .rs-picker-subtle .rs-btn:disabled,
.rs-theme-high-contrast .rs-picker-subtle .rs-picker-toggle:disabled,
.rs-theme-high-contrast .rs-picker-subtle .rs-btn.rs-btn-disabled,
.rs-theme-high-contrast .rs-picker-subtle .rs-picker-toggle.rs-btn-disabled {
  opacity: 0.5;
}
.rs-picker-subtle .rs-btn-active,
.rs-picker-subtle .rs-picker-toggle-active {
  background-color: var(--rs-btn-subtle-hover-bg);
  color: var(--rs-btn-subtle-hover-text);
}
/* stylelint-disable-next-line */
.rs-picker-toggle-wrapper .rs-picker-toggle.rs-btn {
  padding-right: 32px;
}
.rs-picker-has-value.rs-picker-cleanable .rs-picker-toggle.rs-btn {
  padding-right: 44px;
}
.rs-picker-toggle.rs-btn .rs-picker-toggle-caret,
.rs-picker-toggle.rs-btn .rs-picker-toggle-clean {
  top: 8px;
}
.rs-picker-default .rs-picker-toggle.rs-btn .rs-picker-toggle-caret,
.rs-picker-default .rs-picker-toggle.rs-btn .rs-picker-toggle-clean {
  top: 7px;
}
.rs-picker-toggle.rs-btn .rs-picker-toggle-caret {
  right: 12px;
}
.rs-picker-toggle.rs-btn .rs-picker-toggle-clean {
  right: 30px;
}
.rs-picker-input:not(.rs-picker-tag) .rs-picker-toggle.rs-btn ~ .rs-picker-tag-wrapper .rs-picker-search-input {
  padding: 7px 12px;
  font-size: 14px;
  line-height: 1.42857143;
}
.rs-picker-tag .rs-picker-toggle.rs-btn ~ .rs-picker-tag-wrapper {
  padding-bottom: 5px;
}
.rs-picker-tag .rs-picker-toggle.rs-btn ~ .rs-picker-tag-wrapper .rs-tag {
  margin-top: 5px;
}
.rs-picker-tag .rs-picker-toggle.rs-btn ~ .rs-picker-tag-wrapper .rs-picker-search-input {
  padding-left: 12px;
  margin-top: 5px;
  font-size: 14px;
}
.rs-picker-tag .rs-picker-toggle.rs-btn ~ .rs-picker-tag-wrapper input {
  height: 18px;
}
.rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn {
  padding-top: 7px;
  padding-bottom: 7px;
  box-shadow: none;
}
.rs-picker-date .rs-picker-toggle.rs-btn,
.rs-picker-daterange .rs-picker-toggle.rs-btn {
  padding-right: 36px;
}
.rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-clean,
.rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-clean,
.rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-caret,
.rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-caret {
  top: 8px;
}
.rs-picker-subtle .rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-clean,
.rs-picker-subtle .rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-clean,
.rs-picker-subtle .rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-caret,
.rs-picker-subtle .rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-caret {
  top: 7px;
}
.rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-caret,
.rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-caret {
  right: 12px;
}
.rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-clean,
.rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-clean {
  right: 30px;
}
.rs-picker-default .rs-picker-toggle.rs-btn {
  padding-top: 7px;
  padding-bottom: 7px;
}
.rs-picker-toggle.rs-btn-lg {
  font-size: 16px;
  line-height: 22px;
  padding: 10px 16px;
}
.rs-picker-toggle-wrapper .rs-picker-toggle.rs-btn-lg {
  padding-right: 36px;
}
.rs-picker-has-value.rs-picker-cleanable .rs-picker-toggle.rs-btn-lg {
  padding-right: 50px;
}
.rs-picker-toggle.rs-btn-lg .rs-picker-toggle-caret,
.rs-picker-toggle.rs-btn-lg .rs-picker-toggle-clean {
  top: 10px;
}
.rs-picker-default .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-caret,
.rs-picker-default .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-clean {
  top: 9px;
}
.rs-picker-toggle.rs-btn-lg .rs-picker-toggle-caret {
  right: 16px;
}
.rs-picker-toggle.rs-btn-lg .rs-picker-toggle-clean {
  right: 32px;
}
.rs-picker-default .rs-picker-toggle.rs-btn-lg {
  padding-left: 15px;
}
.rs-picker-input:not(.rs-picker-tag) .rs-picker-toggle.rs-btn-lg ~ .rs-picker-tag-wrapper .rs-picker-search-input {
  padding: 8px 16px;
  font-size: 16px;
  line-height: 1.375;
}
.rs-picker-tag .rs-picker-toggle.rs-btn-lg ~ .rs-picker-tag-wrapper {
  padding-bottom: 6px;
}
.rs-picker-tag .rs-picker-toggle.rs-btn-lg ~ .rs-picker-tag-wrapper .rs-tag {
  margin-top: 6px;
}
.rs-picker-tag .rs-picker-toggle.rs-btn-lg ~ .rs-picker-tag-wrapper .rs-picker-search-input {
  padding-left: 16px;
  margin-top: 6px;
  font-size: 16px;
}
.rs-picker-tag .rs-picker-toggle.rs-btn-lg ~ .rs-picker-tag-wrapper input {
  height: 20px;
}
.rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn-lg {
  padding-top: 8px;
  padding-bottom: 8px;
  box-shadow: none;
}
.rs-picker-date .rs-picker-toggle.rs-btn-lg,
.rs-picker-daterange .rs-picker-toggle.rs-btn-lg {
  padding-right: 42px;
}
.rs-picker-date .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-clean,
.rs-picker-daterange .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-clean,
.rs-picker-date .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-caret,
.rs-picker-daterange .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-caret {
  top: 10px;
}
.rs-picker-subtle .rs-picker-date .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-clean,
.rs-picker-subtle .rs-picker-daterange .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-clean,
.rs-picker-subtle .rs-picker-date .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-caret,
.rs-picker-subtle .rs-picker-daterange .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-caret {
  top: 9px;
}
.rs-picker-date .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-caret,
.rs-picker-daterange .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-caret {
  right: 16px;
}
.rs-picker-date .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-clean,
.rs-picker-daterange .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-clean {
  right: 36px;
}
.rs-picker-default .rs-picker-toggle.rs-btn-lg {
  padding-top: 9px;
  padding-bottom: 9px;
}
.rs-btn-ghost.rs-picker-toggle.rs-btn-lg {
  padding: 9px 15px;
}
.rs-btn-icon.rs-picker-toggle.rs-btn-lg {
  padding: 11px 11px;
  line-height: 20px;
}
.rs-btn-icon.rs-picker-toggle.rs-btn-lg > .rs-icon {
  font-size: 20px;
}
.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-lg {
  line-height: 22px;
}
.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-lg > .rs-icon {
  padding: 11px 11px;
  width: 42px;
  height: 42px;
}
.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-lg.rs-btn-icon-placement-left {
  padding: 10px 16px 10px 58px;
}
.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-lg.rs-btn-icon-placement-left > .rs-icon {
  left: 0;
  border-right: var(--rs-btn-default-border, none);
}
.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-lg.rs-btn-icon-placement-right {
  padding: 10px 58px 10px 16px;
}
.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-lg.rs-btn-icon-placement-right > .rs-icon {
  right: 0;
  border-left: var(--rs-btn-default-border, none);
}
.rs-picker-toggle.rs-btn-lg .rs-picker-value-count {
  line-height: 22px;
}
.rs-picker-toggle-wrapper .rs-picker-toggle.rs-btn-md {
  padding-right: 32px;
}
.rs-picker-has-value.rs-picker-cleanable .rs-picker-toggle.rs-btn-md {
  padding-right: 44px;
}
.rs-picker-toggle.rs-btn-md .rs-picker-toggle-caret,
.rs-picker-toggle.rs-btn-md .rs-picker-toggle-clean {
  top: 8px;
}
.rs-picker-default .rs-picker-toggle.rs-btn-md .rs-picker-toggle-caret,
.rs-picker-default .rs-picker-toggle.rs-btn-md .rs-picker-toggle-clean {
  top: 7px;
}
.rs-picker-toggle.rs-btn-md .rs-picker-toggle-caret {
  right: 12px;
}
.rs-picker-toggle.rs-btn-md .rs-picker-toggle-clean {
  right: 30px;
}
.rs-picker-default .rs-picker-toggle.rs-btn-md {
  padding-left: 11px;
}
.rs-picker-input:not(.rs-picker-tag) .rs-picker-toggle.rs-btn-md ~ .rs-picker-tag-wrapper .rs-picker-search-input {
  padding: 7px 12px;
  font-size: 14px;
  line-height: 1.42857143;
}
.rs-picker-tag .rs-picker-toggle.rs-btn-md ~ .rs-picker-tag-wrapper {
  padding-bottom: 5px;
}
.rs-picker-tag .rs-picker-toggle.rs-btn-md ~ .rs-picker-tag-wrapper .rs-tag {
  margin-top: 5px;
}
.rs-picker-tag .rs-picker-toggle.rs-btn-md ~ .rs-picker-tag-wrapper .rs-picker-search-input {
  padding-left: 12px;
  margin-top: 5px;
  font-size: 14px;
}
.rs-picker-tag .rs-picker-toggle.rs-btn-md ~ .rs-picker-tag-wrapper input {
  height: 18px;
}
.rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn-md {
  padding-top: 7px;
  padding-bottom: 7px;
  box-shadow: none;
}
.rs-picker-date .rs-picker-toggle.rs-btn-md,
.rs-picker-daterange .rs-picker-toggle.rs-btn-md {
  padding-right: 36px;
}
.rs-picker-date .rs-picker-toggle.rs-btn-md .rs-picker-toggle-clean,
.rs-picker-daterange .rs-picker-toggle.rs-btn-md .rs-picker-toggle-clean,
.rs-picker-date .rs-picker-toggle.rs-btn-md .rs-picker-toggle-caret,
.rs-picker-daterange .rs-picker-toggle.rs-btn-md .rs-picker-toggle-caret {
  top: 8px;
}
.rs-picker-subtle .rs-picker-date .rs-picker-toggle.rs-btn-md .rs-picker-toggle-clean,
.rs-picker-subtle .rs-picker-daterange .rs-picker-toggle.rs-btn-md .rs-picker-toggle-clean,
.rs-picker-subtle .rs-picker-date .rs-picker-toggle.rs-btn-md .rs-picker-toggle-caret,
.rs-picker-subtle .rs-picker-daterange .rs-picker-toggle.rs-btn-md .rs-picker-toggle-caret {
  top: 7px;
}
.rs-picker-date .rs-picker-toggle.rs-btn-md .rs-picker-toggle-caret,
.rs-picker-daterange .rs-picker-toggle.rs-btn-md .rs-picker-toggle-caret {
  right: 12px;
}
.rs-picker-date .rs-picker-toggle.rs-btn-md .rs-picker-toggle-clean,
.rs-picker-daterange .rs-picker-toggle.rs-btn-md .rs-picker-toggle-clean {
  right: 30px;
}
.rs-picker-default .rs-picker-toggle.rs-btn-md {
  padding-top: 7px;
  padding-bottom: 7px;
}
.rs-picker-toggle.rs-btn-sm {
  font-size: 14px;
  line-height: 20px;
  padding: 5px 10px;
}
.rs-btn-ghost.rs-picker-toggle.rs-btn-sm {
  padding: 4px 9px;
}
.rs-btn-icon.rs-picker-toggle.rs-btn-sm {
  padding: 7px 7px;
  line-height: 16px;
}
.rs-btn-icon.rs-picker-toggle.rs-btn-sm > .rs-icon {
  font-size: 16px;
}
.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-sm {
  line-height: 20px;
}
.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-sm > .rs-icon {
  padding: 7px 7px;
  width: 30px;
  height: 30px;
}
.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-sm.rs-btn-icon-placement-left {
  padding: 5px 10px 5px 40px;
}
.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-sm.rs-btn-icon-placement-left > .rs-icon {
  left: 0;
  border-right: var(--rs-btn-default-border, none);
}
.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-sm.rs-btn-icon-placement-right {
  padding: 5px 40px 5px 10px;
}
.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-sm.rs-btn-icon-placement-right > .rs-icon {
  right: 0;
  border-left: var(--rs-btn-default-border, none);
}
.rs-picker-toggle-wrapper .rs-picker-toggle.rs-btn-sm {
  padding-right: 30px;
}
.rs-picker-has-value.rs-picker-cleanable .rs-picker-toggle.rs-btn-sm {
  padding-right: 42px;
}
.rs-picker-toggle.rs-btn-sm .rs-picker-toggle-caret,
.rs-picker-toggle.rs-btn-sm .rs-picker-toggle-clean {
  top: 5px;
}
.rs-picker-default .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-caret,
.rs-picker-default .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-clean {
  top: 4px;
}
.rs-picker-toggle.rs-btn-sm .rs-picker-toggle-caret {
  right: 10px;
}
.rs-picker-toggle.rs-btn-sm .rs-picker-toggle-clean {
  right: 28px;
}
.rs-picker-default .rs-picker-toggle.rs-btn-sm {
  padding-left: 9px;
}
.rs-picker-input:not(.rs-picker-tag) .rs-picker-toggle.rs-btn-sm ~ .rs-picker-tag-wrapper .rs-picker-search-input {
  padding: 4px 10px;
  font-size: 14px;
  line-height: 1.42857143;
}
.rs-picker-tag .rs-picker-toggle.rs-btn-sm ~ .rs-picker-tag-wrapper {
  padding-bottom: 2px;
}
.rs-picker-tag .rs-picker-toggle.rs-btn-sm ~ .rs-picker-tag-wrapper .rs-tag {
  margin-top: 2px;
}
.rs-picker-tag .rs-picker-toggle.rs-btn-sm ~ .rs-picker-tag-wrapper .rs-picker-search-input {
  padding-left: 10px;
  margin-top: 2px;
  font-size: 14px;
}
.rs-picker-tag .rs-picker-toggle.rs-btn-sm ~ .rs-picker-tag-wrapper input {
  height: 18px;
}
.rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn-sm {
  padding-top: 4px;
  padding-bottom: 4px;
  box-shadow: none;
}
.rs-picker-date .rs-picker-toggle.rs-btn-sm,
.rs-picker-daterange .rs-picker-toggle.rs-btn-sm {
  padding-right: 34px;
}
.rs-picker-date .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-clean,
.rs-picker-daterange .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-clean,
.rs-picker-date .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-caret,
.rs-picker-daterange .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-caret {
  top: 4px;
}
.rs-picker-subtle .rs-picker-date .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-clean,
.rs-picker-subtle .rs-picker-daterange .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-clean,
.rs-picker-subtle .rs-picker-date .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-caret,
.rs-picker-subtle .rs-picker-daterange .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-caret {
  top: 3px;
}
.rs-picker-date .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-caret,
.rs-picker-daterange .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-caret {
  right: 10px;
}
.rs-picker-date .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-clean,
.rs-picker-daterange .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-clean {
  right: 28px;
}
.rs-picker-default .rs-picker-toggle.rs-btn-sm {
  padding-top: 4px;
  padding-bottom: 4px;
}
.rs-picker-toggle.rs-btn-xs {
  font-size: 12px;
  line-height: 20px;
  padding: 2px 8px;
  /* stylelint-disable */
  /* stylelint-enable */
}
.rs-btn-ghost.rs-picker-toggle.rs-btn-xs {
  padding: 1px 7px;
}
.rs-btn-icon.rs-picker-toggle.rs-btn-xs {
  padding: 6px 6px;
  line-height: 12px;
}
.rs-btn-icon.rs-picker-toggle.rs-btn-xs > .rs-icon {
  font-size: 12px;
}
.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-xs {
  line-height: 20px;
}
.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-xs > .rs-icon {
  padding: 6px 6px;
  width: 24px;
  height: 24px;
}
.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-xs.rs-btn-icon-placement-left {
  padding: 2px 8px 2px 32px;
}
.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-xs.rs-btn-icon-placement-left > .rs-icon {
  left: 0;
  border-right: var(--rs-btn-default-border, none);
}
.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-xs.rs-btn-icon-placement-right {
  padding: 2px 32px 2px 8px;
}
.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-xs.rs-btn-icon-placement-right > .rs-icon {
  right: 0;
  border-left: var(--rs-btn-default-border, none);
}
.rs-picker-toggle-wrapper .rs-picker-toggle.rs-btn-xs {
  padding-right: 28px;
}
.rs-picker-has-value.rs-picker-cleanable .rs-picker-toggle.rs-btn-xs {
  padding-right: 40px;
}
.rs-picker-toggle.rs-btn-xs .rs-picker-toggle-caret,
.rs-picker-toggle.rs-btn-xs .rs-picker-toggle-clean {
  top: 2px;
}
.rs-picker-default .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-caret,
.rs-picker-default .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-clean {
  top: 1px;
}
.rs-picker-toggle.rs-btn-xs .rs-picker-toggle-caret {
  right: 8px;
}
.rs-picker-toggle.rs-btn-xs .rs-picker-toggle-clean {
  right: 26px;
}
.rs-picker-default .rs-picker-toggle.rs-btn-xs {
  padding-left: 7px;
}
.rs-picker-input:not(.rs-picker-tag) .rs-picker-toggle.rs-btn-xs ~ .rs-picker-tag-wrapper .rs-picker-search-input {
  padding: 1px 8px;
  font-size: 12px;
  line-height: 1.66666667;
}
.rs-picker-tag .rs-picker-toggle.rs-btn-xs ~ .rs-picker-tag-wrapper {
  padding-bottom: -1px;
}
.rs-picker-tag .rs-picker-toggle.rs-btn-xs ~ .rs-picker-tag-wrapper .rs-tag {
  margin-top: -1px;
}
.rs-picker-tag .rs-picker-toggle.rs-btn-xs ~ .rs-picker-tag-wrapper .rs-picker-search-input {
  padding-left: 8px;
  margin-top: -1px;
  font-size: 12px;
}
.rs-picker-tag .rs-picker-toggle.rs-btn-xs ~ .rs-picker-tag-wrapper input {
  height: 18px;
}
.rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn-xs {
  padding-top: 1px;
  padding-bottom: 1px;
  box-shadow: none;
}
.rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn-xs ~ .rs-picker-tag-wrapper {
  padding-bottom: 1px;
}
.rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn-xs ~ .rs-picker-tag-wrapper .rs-tag {
  margin-top: 1px;
}
.rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn-xs ~ .rs-picker-tag-wrapper .rs-picker-search-input {
  padding-top: 1px;
  padding-bottom: 1px;
}
.rs-picker-date .rs-picker-toggle.rs-btn-xs,
.rs-picker-daterange .rs-picker-toggle.rs-btn-xs {
  padding-right: 30px;
}
.rs-picker-date .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-clean,
.rs-picker-daterange .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-clean,
.rs-picker-date .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-caret,
.rs-picker-daterange .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-caret {
  top: 2px;
}
.rs-picker-subtle .rs-picker-date .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-clean,
.rs-picker-subtle .rs-picker-daterange .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-clean,
.rs-picker-subtle .rs-picker-date .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-caret,
.rs-picker-subtle .rs-picker-daterange .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-caret {
  top: 1px;
}
.rs-picker-date .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-caret,
.rs-picker-daterange .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-caret {
  right: 8px;
}
.rs-picker-date .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-clean,
.rs-picker-daterange .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-clean {
  right: 24px;
}
.rs-picker-default .rs-picker-toggle.rs-btn-xs {
  padding-top: 1px;
  padding-bottom: 1px;
}
/* stylelint-disable-next-line */
.rs-picker-toggle {
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}
.rs-picker-default .rs-picker-toggle {
  position: relative;
  z-index: 5;
  padding-right: 32px;
  display: inline-block;
  cursor: pointer;
  color: var(--rs-text-primary);
  border: 1px solid var(--rs-border-primary);
}
.rs-picker-disabled .rs-picker-toggle {
  cursor: not-allowed;
}
.rs-picker-toggle .rs-picker-default:not(.rs-picker-disabled):hover,
.rs-picker-toggle .rs-picker-default:not(.rs-picker-disabled):focus {
  border-color: var(--rs-input-focus-border);
}
.rs-picker-subtle .rs-picker-toggle {
  position: relative;
  z-index: 5;
  padding-right: 32px;
  display: inline-block;
  cursor: pointer;
}
.rs-picker-subtle.rs-picker-disabled .rs-picker-toggle {
  cursor: not-allowed;
}
.rs-picker-subtle.rs-picker-disabled .rs-picker-toggle:hover,
.rs-picker-subtle.rs-picker-disabled .rs-picker-toggle:focus,
.rs-picker-subtle.rs-picker-disabled .rs-picker-toggle:active {
  background: none;
}
.rs-picker-subtle.rs-picker-disabled .rs-picker-toggle:hover::after,
.rs-picker-subtle.rs-picker-disabled .rs-picker-toggle:focus::after,
.rs-picker-subtle.rs-picker-disabled .rs-picker-toggle:active::after {
  display: none;
}
.rs-picker-toggle-label {
  color: var(--rs-text-primary);
}
.rs-picker-toggle-value {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}
.rs-picker-cleanable.rs-picker-has-value .rs-picker-toggle {
  padding-right: 44px;
}
.rs-picker-date.rs-picker-cleanable.rs-picker-has-value .rs-picker-toggle,
.rs-picker-daterange.rs-picker-cleanable.rs-picker-has-value .rs-picker-toggle {
  padding-right: 52px;
}
.rs-picker-toggle-clean {
  display: flex;
  align-items: center;
  margin-left: 2px;
  position: absolute;
  top: 8px;
  right: 30px;
  height: 20px;
  font-size: 12px;
  background: inherit;
  color: var(--rs-text-secondary);
  transition: 0.2s color linear;
  cursor: pointer;
}
.rs-picker-toggle-clean.rs-btn-close {
  padding: 4px 0;
}
.rs-picker-toggle-clean:hover {
  color: var(--rs-state-error);
}
.rs-picker-date .rs-picker-toggle-clean,
.rs-picker-daterange .rs-picker-toggle-clean {
  right: 38px;
}
.rs-picker-toggle-caret {
  display: flex;
  align-items: center;
  margin-left: 2px;
  position: absolute;
  top: 8px;
  right: 12px;
  height: 20px;
  font-size: 12px;
  color: var(--rs-text-secondary);
}
.rs-picker-menu {
  position: absolute;
  text-align: left;
  z-index: 7;
  border-radius: 6px;
  background-color: var(--rs-bg-overlay);
  box-shadow: var(--rs-shadow-overlay);
  overflow: hidden;
  transition: none;
  display: flex;
  flex-direction: column;
  /* stylelint-disable-next-line */
}
.rs-theme-high-contrast .rs-picker-menu {
  border: 1px solid var(--rs-border-primary);
}
.rs-modal-open .rs-picker-menu {
  z-index: 1055;
}
.rs-drawer-open .rs-picker-menu {
  z-index: 1055;
}
.rs-picker-menu.rs-picker-select-menu,
.rs-picker-menu.rs-picker-check-menu {
  padding-top: 6px;
}
.rs-picker-menu .rs-picker-search-bar {
  position: relative;
  padding: 6px 12px 12px;
}
.rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input {
  display: block;
  width: 100%;
  color: var(--rs-text-primary);
  background-color: var(--rs-input-bg);
  background-image: none;
  border: 1px solid var(--rs-border-primary);
  transition: border-color ease-in-out 0.3s;
  border-radius: 6px;
  /* stylelint-disable */
  padding: 7px 11px;
  font-size: 14px;
  line-height: 1.42857143;
  /* stylelint-enable */
  min-width: 200px;
  padding-right: 36px;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input {
    height: 36px;
  }
}
textarea.rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input {
  height: auto;
}
.rs-theme-high-contrast .rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input {
  transition: none;
}
.rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input::placeholder {
  color: var(--rs-text-secondary);
}
.rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input:focus,
.rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input:hover:not(:disabled) {
  border-color: var(--rs-input-focus-border);
  outline: 0;
}
.rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input:focus {
  outline: 3px solid var(--rs-color-focus-ring);
}
.rs-theme-high-contrast .rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input:focus {
  outline-offset: 2px;
}
.rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input:disabled {
  background-color: var(--rs-input-disabled-bg);
  color: var(--rs-text-disabled);
}
.rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-search-icon {
  position: absolute;
  width: 14px;
  color: var(--rs-text-secondary);
  font-size: 14px;
  height: 20px;
  top: 14px;
  right: 24px;
}
.rs-tree-menu.rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-search-icon,
.rs-check-tree-menu.rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-search-icon {
  top: 7px;
}
.rs-picker-menu .ReactVirtualized__Grid.ReactVirtualized__List:focus {
  outline: none;
}
.rs-picker-menu.rs-picker-inline {
  position: relative;
  box-shadow: none;
}
.rs-picker-default .rs-btn,
.rs-picker-input .rs-btn,
.rs-picker-default .rs-picker-toggle,
.rs-picker-input .rs-picker-toggle {
  background-color: var(--rs-input-bg) !important;
}
.rs-picker-default.rs-picker-disabled .rs-picker-toggle,
.rs-picker-input.rs-picker-disabled .rs-picker-toggle,
.rs-picker-default.rs-picker-disabled .rs-btn,
.rs-picker-input.rs-picker-disabled .rs-btn {
  background-color: var(--rs-input-disabled-bg) !important;
}
.rs-check-item {
  display: block;
}
.rs-check-item:not(.rs-checkbox-disabled):hover,
.rs-check-item:focus,
.rs-check-item.rs-check-item-focus {
  background-color: var(--rs-listbox-option-hover-bg);
}
.rs-theme-high-contrast .rs-check-item:not(.rs-checkbox-disabled):hover,
.rs-theme-high-contrast .rs-check-item:focus,
.rs-theme-high-contrast .rs-check-item.rs-check-item-focus {
  outline: 3px solid var(--rs-color-focus-ring);
  outline-offset: -3px;
  outline-width: 2px;
  color: var(--rs-listbox-option-hover-text);
  text-decoration: underline;
}
.rs-theme-high-contrast .rs-theme-high-contrast .rs-check-item:not(.rs-checkbox-disabled):hover,
.rs-theme-high-contrast .rs-theme-high-contrast .rs-check-item:focus,
.rs-theme-high-contrast .rs-theme-high-contrast .rs-check-item.rs-check-item-focus {
  outline-offset: 2px;
}
.rs-theme-high-contrast .rs-check-item:not(.rs-checkbox-disabled):hover .rs-check-tree-node-text-wrapper,
.rs-theme-high-contrast .rs-check-item:focus .rs-check-tree-node-text-wrapper,
.rs-theme-high-contrast .rs-check-item.rs-check-item-focus .rs-check-tree-node-text-wrapper {
  text-decoration: underline;
}
.rs-check-item .rs-checkbox-checker {
  padding: 0;
}
.rs-check-item .rs-checkbox-checker > label {
  position: relative;
  cursor: pointer;
  display: block;
  padding: 8px 12px 8px 38px;
  transition: color 0.3s linear, background-color 0.3s linear;
  line-height: 20px;
  /* stylelint-disable-next-line */
}
.rs-theme-high-contrast .rs-check-item .rs-checkbox-checker > label {
  transition: none;
}
.rs-check-item .rs-checkbox-checker > label::before {
  background-color: var(--rs-bg-overlay);
}
.rs-theme-high-contrast .rs-check-item .rs-checkbox-checker > label::before {
  background: none;
}
.ReactVirtualized__Grid .rs-check-item .rs-checkbox-checker > label {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.rs-checkbox-disabled.rs-check-item .rs-checkbox-checker > label {
  cursor: not-allowed;
}
.rs-check-item .rs-checkbox-checker > label .rs-checkbox-wrapper {
  left: 12px;
}
.grouped .rs-check-item .rs-checkbox-checker > label .rs-checkbox-wrapper,
.rs-picker-check-menu-group-children .rs-check-item .rs-checkbox-checker > label .rs-checkbox-wrapper {
  left: 26px;
}
.grouped .rs-check-item .rs-checkbox-checker > label,
.rs-picker-check-menu-group-children .rs-check-item .rs-checkbox-checker > label {
  padding-left: 52px;
}
.rs-picker-select-menu-items {
  margin-bottom: 6px;
  overflow-y: auto;
}
.rs-picker-select-menu-items ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.rs-picker-select-menu-items .rs-picker-menu-group:not(:first-child) {
  margin-top: 6px;
  border-top: 1px solid var(--rs-border-primary);
  padding-top: 6px;
}
.rs-picker-select-menu-items .rs-picker-menu-group-title {
  padding: 8px 12px;
  padding-right: 32px;
  position: relative;
  cursor: default;
  transition: color 0.3s linear, background-color 0.3s linear;
  color: var(--rs-text-heading);
}
.rs-picker-select-menu-items .rs-picker-menu-group-title:hover {
  background-color: var(--rs-state-hover-bg);
}
.rs-picker-select-menu-items .rs-picker-menu-group-title .rs-picker-menu-group-caret {
  display: inline-block;
  margin-left: 2px;
  position: absolute;
  top: 8px;
  right: 12px;
  transition: transform 0.3s linear;
  padding: 3px;
}
.rs-picker-select-menu-items .rs-picker-menu-group.folded .rs-picker-menu-group-caret {
  transform: rotate(90deg);
}
.rs-picker-select-menu-item {
  display: block;
  padding: 8px 12px;
  clear: both;
  font-weight: normal;
  line-height: 1.42857143;
  color: var(--rs-text-primary);
  cursor: pointer;
  text-decoration: none;
  width: 100%;
  /* stylelint-disable-next-line */
}
.ReactVirtualized__Grid .rs-picker-select-menu-item {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.rs-picker-select-menu-item:hover,
.rs-picker-select-menu-item:focus,
.rs-picker-select-menu-item.rs-picker-select-menu-item-focus {
  background-color: var(--rs-listbox-option-hover-bg);
  color: var(--rs-listbox-option-hover-text);
}
.rs-theme-high-contrast .rs-picker-select-menu-item:hover,
.rs-theme-high-contrast .rs-picker-select-menu-item:focus,
.rs-theme-high-contrast .rs-picker-select-menu-item.rs-picker-select-menu-item-focus {
  outline: 3px solid var(--rs-color-focus-ring);
  outline-offset: -3px;
  outline-width: 2px;
  color: var(--rs-listbox-option-hover-text);
  text-decoration: underline;
}
.rs-theme-high-contrast .rs-theme-high-contrast .rs-picker-select-menu-item:hover,
.rs-theme-high-contrast .rs-theme-high-contrast .rs-picker-select-menu-item:focus,
.rs-theme-high-contrast .rs-theme-high-contrast .rs-picker-select-menu-item.rs-picker-select-menu-item-focus {
  outline-offset: 2px;
}
.rs-picker-select-menu-item.rs-picker-select-menu-item-active {
  font-weight: bold;
  color: var(--rs-listbox-option-selected-text);
  background-color: var(--rs-listbox-option-selected-bg);
}
.rs-picker-select-menu-item.rs-picker-select-menu-item-disabled {
  color: var(--rs-listbox-option-disabled-text);
  background: none;
  cursor: not-allowed;
}
.grouped .rs-picker-select-menu-item,
.rs-picker-select-menu-group-children .rs-picker-select-menu-item {
  padding-left: 26px;
}
.rs-input {
  display: block;
  width: 100%;
  color: var(--rs-text-primary);
  background-color: var(--rs-input-bg);
  background-image: none;
  border: 1px solid var(--rs-border-primary);
  transition: border-color ease-in-out 0.3s;
  border-radius: 6px;
  /* stylelint-disable */
  padding: 7px 11px;
  font-size: 14px;
  line-height: 1.42857143;
  /* stylelint-enable */
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .rs-input {
    height: 36px;
  }
}
textarea.rs-input {
  height: auto;
}
.rs-theme-high-contrast .rs-input {
  transition: none;
}
.rs-input::placeholder {
  color: var(--rs-text-secondary);
}
.rs-input:focus,
.rs-input:hover:not(:disabled) {
  border-color: var(--rs-input-focus-border);
  outline: 0;
}
.rs-input:focus {
  outline: 3px solid var(--rs-color-focus-ring);
}
.rs-theme-high-contrast .rs-input:focus {
  outline-offset: 2px;
}
.rs-input:disabled {
  background-color: var(--rs-input-disabled-bg);
  color: var(--rs-text-disabled);
}
textarea.rs-input {
  overflow: auto;
  resize: vertical;
}
.rs-input-xs {
  /* stylelint-disable */
  padding: 1px 11px;
  font-size: 12px;
  line-height: 1.66666667;
  /* stylelint-enable */
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .rs-input-xs {
    height: 24px;
  }
}
textarea.rs-input-xs {
  height: auto;
}
.rs-input-lg {
  /* stylelint-disable */
  padding: 9px 11px;
  font-size: 16px;
  line-height: 1.375;
  /* stylelint-enable */
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .rs-input-lg {
    height: 42px;
  }
}
textarea.rs-input-lg {
  height: auto;
}
.rs-input-sm {
  /* stylelint-disable */
  padding: 4px 11px;
  font-size: 14px;
  line-height: 1.42857143;
  /* stylelint-enable */
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .rs-input-sm {
    height: 30px;
  }
}
textarea.rs-input-sm {
  height: auto;
}
.rs-input-number {
  background-color: var(--rs-input-bg);
}
.rs-input-number-btn-group-vertical {
  position: relative;
  width: 20px;
  flex: 0 0 20px;
  display: block;
}
.rs-input-number-btn-group-vertical > .rs-btn {
  display: block;
  float: none;
  width: 100%;
  max-width: 100%;
  position: relative;
  border: none;
}
.rs-input-number-touchspin-up,
.rs-input-number-touchspin-down {
  position: relative;
  border-radius: 0;
  padding: 0;
  box-shadow: none !important;
  height: 17px;
}
.rs-input-number-touchspin-up > .rs-icon,
.rs-input-number-touchspin-down > .rs-icon {
  height: 17px;
}
.rs-input-group-lg .rs-input-number-touchspin-up,
.rs-input-group-lg .rs-input-number-touchspin-down {
  height: 20px;
}
.rs-input-group-lg .rs-input-number-touchspin-up > .rs-icon,
.rs-input-group-lg .rs-input-number-touchspin-down > .rs-icon {
  height: 20px;
}
.rs-input-group-sm .rs-input-number-touchspin-up,
.rs-input-group-sm .rs-input-number-touchspin-down {
  height: 14px;
}
.rs-input-group-sm .rs-input-number-touchspin-up > .rs-icon,
.rs-input-group-sm .rs-input-number-touchspin-down > .rs-icon {
  height: 14px;
}
.rs-input-group-xs .rs-input-number-touchspin-up,
.rs-input-group-xs .rs-input-number-touchspin-down {
  height: 11px;
}
.rs-input-group-xs .rs-input-number-touchspin-up > .rs-icon,
.rs-input-group-xs .rs-input-number-touchspin-down > .rs-icon {
  height: 11px;
}
.rs-input-number-touchspin-up > .rs-icon,
.rs-input-number-touchspin-down > .rs-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.rs-input-number-btn-group-vertical:last-child .rs-input-number-touchspin-up {
  border-top-right-radius: 6px;
}
.rs-input-number-btn-group-vertical:last-child .rs-input-number-touchspin-down {
  border-bottom-right-radius: 6px;
}
.rs-input-number > .rs-input {
  border-bottom-left-radius: 6px !important;
  border-top-left-radius: 6px !important;
}
.rs-input-number > .rs-input:disabled + .rs-input-number-btn-group-vertical .rs-input-number-touchspin-up,
.rs-input-number > .rs-input:disabled + .rs-input-number-btn-group-vertical .rs-input-number-touchspin-down {
  background-color: var(--rs-input-disabled-bg);
}
.rs-input-number input[type='number'] {
  appearance: textfield;
}
.rs-input-number input[type='number']::-webkit-inner-spin-button {
  /* stylelint-disable */
  -webkit-appearance: none;
}
.rs-input-group {
  position: relative;
  display: flex;
  border-radius: 6px;
  transition: border-color ease-in-out 0.3s;
  width: 100%;
  cursor: text;
  color: var(--rs-text-primary);
  border: 1px solid var(--rs-border-primary);
}
.rs-theme-high-contrast .rs-input-group {
  transition: none;
}
.rs-input-group:not(.rs-input-group-disabled):hover,
.rs-input-group:not(.rs-input-group-disabled).rs-input-group-focus,
.rs-input-group:focus-within {
  border-color: var(--rs-input-focus-border);
}
.rs-input-group:not(.rs-input-group-disabled).rs-input-group-focus,
.rs-input-group:focus-within {
  outline: 3px solid var(--rs-color-focus-ring);
}
.rs-theme-high-contrast .rs-input-group:not(.rs-input-group-disabled).rs-input-group-focus,
.rs-theme-high-contrast .rs-input-group:focus-within {
  outline-offset: 2px;
}
.rs-input-group .rs-input-group {
  outline: none !important;
}
.rs-input-group .rs-input-number,
.rs-input-group .rs-input-group-addon,
.rs-input-group .rs-input-group-btn,
.rs-input-group .rs-picker-date .rs-picker-toggle {
  border: none;
  border-radius: 0;
  outline: none;
}
.rs-input-group:not(.rs-input-group-inside) .rs-input {
  border: none;
  border-radius: 0;
  outline: none;
}
.rs-input-group:not(.rs-input-group-inside) .rs-input-number:not(:last-child) .rs-input-number-btn-group-vertical {
  border-radius: 0;
}
.rs-input-group:not(.rs-input-group-inside) .rs-input-number:not(:last-child) .rs-input-number-touchspin-up {
  border-top-right-radius: 0;
}
.rs-input-group:not(.rs-input-group-inside) .rs-input-number:not(:last-child) .rs-input-number-touchspin-down {
  border-bottom-right-radius: 0;
}
.rs-input-group:not(.rs-input-group-inside) .rs-input-number:not(:first-child) .rs-input {
  border-radius: 0 !important;
}
.rs-input-group:not(.rs-input-group-inside) > :first-child,
.rs-input-group:not(.rs-input-group-inside) .rs-auto-complete:first-child .rs-input,
.rs-input-group:not(.rs-input-group-inside) .rs-picker-date:first-child .rs-picker-toggle,
.rs-input-group:not(.rs-input-group-inside) .rs-form-control-wrapper:first-child > .rs-input {
  border-bottom-left-radius: 6px;
  border-top-left-radius: 6px;
}
.rs-input-group:not(.rs-input-group-inside) > :last-child,
.rs-input-group:not(.rs-input-group-inside) .rs-auto-complete:last-child .rs-input,
.rs-input-group:not(.rs-input-group-inside) .rs-picker-date:last-child .rs-picker-toggle,
.rs-input-group:not(.rs-input-group-inside) .rs-form-control-wrapper:last-child > .rs-input {
  border-bottom-right-radius: 6px;
  border-top-right-radius: 6px;
}
.rs-input-group > .rs-input,
.rs-input-group > .rs-form-control-wrapper {
  flex: 1 1 auto;
}
.rs-input-group .rs-form-control-wrapper > .rs-input {
  width: 100%;
}
.rs-input-group > .rs-input {
  position: relative;
  z-index: 2;
}
.rs-input-group > .rs-input:focus {
  z-index: 3;
}
.rs-input-group input.rs-input ~ .rs-input-group-addon,
.rs-input-group .rs-form-control-wrapper ~ .rs-input-group-addon {
  border-left: none;
  left: auto;
  right: 0;
}
.rs-input-group input.rs-input ~ .rs-input-group-addon:not(:last-child),
.rs-input-group .rs-form-control-wrapper ~ .rs-input-group-addon:not(:last-child) {
  border-right: 0;
}
.rs-input-group.rs-input-group-inside {
  width: 100%;
}
.rs-input-group.rs-input-group-inside .rs-input {
  display: block;
  width: 100%;
  border: none;
  padding-right: 36px;
}
.rs-input-group.rs-input-group-inside .rs-input-group-btn,
.rs-input-group.rs-input-group-inside .rs-input-group-addon {
  position: absolute;
  z-index: 4;
  flex: 0 0 auto;
  width: auto;
}
.rs-input-group.rs-input-group-inside .rs-input-group-btn {
  color: var(--rs-btn-subtle-text);
  background-color: transparent;
  border: none;
  color: inherit;
  position: absolute;
  height: 34px;
  padding: 7px 11px;
  border-radius: 0 6px 6px 0;
}
.rs-input-group.rs-input-group-inside .rs-input-group-btn:hover,
.rs-input-group.rs-input-group-inside .rs-input-group-btn:focus {
  color: var(--rs-btn-subtle-hover-text);
  background-color: var(--rs-btn-subtle-hover-bg);
}
.rs-input-group.rs-input-group-inside .rs-input-group-btn:active,
.rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-active {
  color: var(--rs-btn-subtle-active-text);
  background-color: var(--rs-btn-subtle-active-bg);
}
.rs-input-group.rs-input-group-inside .rs-input-group-btn:disabled,
.rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-disabled {
  color: var(--rs-btn-subtle-disabled-text);
  background: none;
}
.rs-theme-high-contrast .rs-input-group.rs-input-group-inside .rs-input-group-btn:disabled,
.rs-theme-high-contrast .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-disabled {
  opacity: 0.5;
}
.rs-input-group.rs-input-group-inside .rs-input-group-btn > .rs-icon {
  font-size: 14px;
  line-height: 1.42857143;
}
.rs-input-group.rs-input-group-inside .rs-input-group-btn:focus {
  outline: none;
}
.rs-input-group.rs-input-group-inside .rs-input-group-btn:first-child {
  border-bottom-left-radius: 6px;
  border-top-left-radius: 6px;
}
.rs-input-group.rs-input-group-inside .rs-input-group-btn:last-child {
  border-bottom-right-radius: 6px;
  border-top-right-radius: 6px;
}
.rs-input-group.rs-input-group-inside .rs-input-group-addon {
  top: 0;
  background: none;
  border: none;
  padding: 11px 13px 8px 13px;
}
.rs-input-group.rs-input-group-inside .rs-input-group-btn ~ input.rs-input,
.rs-input-group.rs-input-group-inside .rs-input-group-addon ~ input.rs-input,
.rs-input-group.rs-input-group-inside .rs-input-group-btn ~ .rs-auto-complete > input.rs-input,
.rs-input-group.rs-input-group-inside .rs-input-group-addon ~ .rs-auto-complete > input.rs-input,
.rs-input-group.rs-input-group-inside .rs-input-group-addon ~ .rs-form-control-wrapper > input.rs-input,
.rs-input-group.rs-input-group-inside .rs-input-group-btn ~ .rs-form-control-wrapper > input.rs-input {
  padding-left: 36px;
  padding-right: 12px;
}
.rs-input-group.rs-input-group-inside input.rs-input ~ .rs-input-group-btn,
.rs-input-group.rs-input-group-inside .rs-auto-complete ~ .rs-input-group-addon,
.rs-input-group.rs-input-group-inside .rs-form-control-wrapper ~ .rs-input-group-addon,
.rs-input-group.rs-input-group-inside .rs-form-control-wrapper ~ .rs-input-group-btn {
  left: inherit;
  right: 0;
}
.rs-input-group.rs-input-group-inside .rs-auto-complete ~ .rs-input-group-btn,
.rs-input-group.rs-input-group-inside .rs-form-control-wrapper ~ .rs-input-group-btn {
  left: inherit;
  right: 1px;
}
.rs-input-group-disabled {
  background-color: var(--rs-input-disabled-bg);
  color: var(--rs-text-disabled);
  cursor: not-allowed;
}
.rs-input-group-disabled .rs-input,
.rs-input-group-disabled .rs-input-group-btn,
.rs-input-group-disabled .rs-input-group-addon {
  color: inherit;
}
.rs-input-group-lg > .rs-input {
  /* stylelint-disable */
  padding: 9px 11px;
  font-size: 16px;
  line-height: 1.375;
  /* stylelint-enable */
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .rs-input-group-lg > .rs-input {
    height: 42px;
  }
}
textarea.rs-input-group-lg > .rs-input {
  height: auto;
}
.rs-input-group-lg.rs-input-group-inside > .rs-input {
  padding-right: 46px;
}
.rs-input-group-lg.rs-input-group > .rs-input {
  height: 40px;
}
.rs-input-group-lg.rs-input-group > .rs-input-group-addon {
  height: 40px;
}
.rs-input-group-lg.rs-input-group > .rs-input-group-addon > .rs-icon {
  font-size: inherit;
}
.rs-input-group-lg.rs-input-group:not(.rs-input-group-inside) > .rs-input-group-addon {
  padding-top: 8px;
  padding-bottom: 8px;
}
.rs-input-group-md > .rs-input {
  /* stylelint-disable */
  padding: 7px 11px;
  font-size: 14px;
  line-height: 1.42857143;
  /* stylelint-enable */
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .rs-input-group-md > .rs-input {
    height: 36px;
  }
}
textarea.rs-input-group-md > .rs-input {
  height: auto;
}
.rs-input-group-md.rs-input-group-inside > .rs-input {
  padding-right: 36px;
}
.rs-input-group-md.rs-input-group > .rs-input {
  height: 34px;
}
.rs-input-group-md.rs-input-group > .rs-input-group-addon {
  height: 34px;
}
.rs-input-group-md.rs-input-group > .rs-input-group-addon > .rs-icon {
  font-size: inherit;
}
.rs-input-group-md.rs-input-group:not(.rs-input-group-inside) > .rs-input-group-addon {
  padding-top: 6px;
  padding-bottom: 6px;
}
.rs-input-group-sm > .rs-input {
  /* stylelint-disable */
  padding: 4px 11px;
  font-size: 14px;
  line-height: 1.42857143;
  /* stylelint-enable */
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .rs-input-group-sm > .rs-input {
    height: 30px;
  }
}
textarea.rs-input-group-sm > .rs-input {
  height: auto;
}
.rs-input-group-sm.rs-input-group-inside > .rs-input {
  padding-right: 30px;
}
.rs-input-group-sm.rs-input-group > .rs-input {
  height: 28px;
}
.rs-input-group-sm.rs-input-group > .rs-input-group-addon {
  height: 28px;
}
.rs-input-group-sm.rs-input-group > .rs-input-group-addon > .rs-icon {
  font-size: inherit;
}
.rs-input-group-sm.rs-input-group:not(.rs-input-group-inside) > .rs-input-group-addon {
  padding-top: 3px;
  padding-bottom: 3px;
}
.rs-input-group-xs > .rs-input {
  /* stylelint-disable */
  padding: 1px 11px;
  font-size: 12px;
  line-height: 1.66666667;
  /* stylelint-enable */
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .rs-input-group-xs > .rs-input {
    height: 24px;
  }
}
textarea.rs-input-group-xs > .rs-input {
  height: auto;
}
.rs-input-group-xs.rs-input-group-inside > .rs-input {
  padding-right: 26px;
}
.rs-input-group-xs.rs-input-group > .rs-input {
  height: 22px;
}
.rs-input-group-xs.rs-input-group > .rs-input-group-addon {
  height: 22px;
}
.rs-input-group-xs.rs-input-group > .rs-input-group-addon > .rs-icon {
  font-size: inherit;
}
.rs-input-group.rs-input-group-lg > .rs-input-group-addon {
  /* stylelint-disable */
  padding: 9px 11px;
  font-size: 16px;
  line-height: 1.375;
  /* stylelint-enable */
  min-width: 44px;
  padding-left: 15px;
  padding-right: 15px;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .rs-input-group.rs-input-group-lg > .rs-input-group-addon {
    height: 42px;
  }
}
textarea.rs-input-group.rs-input-group-lg > .rs-input-group-addon {
  height: auto;
}
.rs-input-group.rs-input-group-md > .rs-input-group-addon {
  /* stylelint-disable */
  padding: 7px 11px;
  font-size: 14px;
  line-height: 1.42857143;
  /* stylelint-enable */
  min-width: 36px;
  padding-left: 11px;
  padding-right: 11px;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .rs-input-group.rs-input-group-md > .rs-input-group-addon {
    height: 36px;
  }
}
textarea.rs-input-group.rs-input-group-md > .rs-input-group-addon {
  height: auto;
}
.rs-input-group.rs-input-group-sm > .rs-input-group-addon {
  /* stylelint-disable */
  padding: 4px 11px;
  font-size: 14px;
  line-height: 1.42857143;
  /* stylelint-enable */
  min-width: 32px;
  padding-left: 9px;
  padding-right: 9px;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .rs-input-group.rs-input-group-sm > .rs-input-group-addon {
    height: 30px;
  }
}
textarea.rs-input-group.rs-input-group-sm > .rs-input-group-addon {
  height: auto;
}
.rs-input-group.rs-input-group-xs > .rs-input-group-addon {
  /* stylelint-disable */
  padding: 1px 11px;
  font-size: 12px;
  line-height: 1.66666667;
  /* stylelint-enable */
  min-width: 28px;
  padding-left: 7px;
  padding-right: 7px;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .rs-input-group.rs-input-group-xs > .rs-input-group-addon {
    height: 24px;
  }
}
textarea.rs-input-group.rs-input-group-xs > .rs-input-group-addon {
  height: auto;
}
.rs-input-group-inside.rs-input-group-lg > .rs-input-group-btn {
  /* stylelint-disable */
  padding: 9px 11px;
  font-size: 16px;
  line-height: 1.375;
  /* stylelint-enable */
  height: 40px;
  padding: 9px 15px;
  border-radius: 0 6px 6px 0;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .rs-input-group-inside.rs-input-group-lg > .rs-input-group-btn {
    height: 42px;
  }
}
textarea.rs-input-group-inside.rs-input-group-lg > .rs-input-group-btn {
  height: auto;
}
.rs-input-group-inside.rs-input-group-lg > .rs-input-group-btn > .rs-icon {
  font-size: 16px;
  line-height: 1.375;
}
.rs-input-group-inside.rs-input-group-md > .rs-input-group-btn {
  /* stylelint-disable */
  font-size: 14px;
  line-height: 1.42857143;
  /* stylelint-enable */
  height: 34px;
  padding: 7px 11px;
  border-radius: 0 6px 6px 0;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .rs-input-group-inside.rs-input-group-md > .rs-input-group-btn {
    height: 36px;
  }
}
textarea.rs-input-group-inside.rs-input-group-md > .rs-input-group-btn {
  height: auto;
}
.rs-input-group-inside.rs-input-group-md > .rs-input-group-btn > .rs-icon {
  font-size: 14px;
  line-height: 1.42857143;
}
.rs-input-group-inside.rs-input-group-sm > .rs-input-group-btn {
  /* stylelint-disable */
  padding: 4px 11px;
  font-size: 14px;
  line-height: 1.42857143;
  /* stylelint-enable */
  height: 28px;
  padding: 4px 9px;
  border-radius: 0 6px 6px 0;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .rs-input-group-inside.rs-input-group-sm > .rs-input-group-btn {
    height: 30px;
  }
}
textarea.rs-input-group-inside.rs-input-group-sm > .rs-input-group-btn {
  height: auto;
}
.rs-input-group-inside.rs-input-group-sm > .rs-input-group-btn > .rs-icon {
  font-size: 12px;
  line-height: 1.66666667;
}
.rs-input-group-inside.rs-input-group-xs > .rs-input-group-btn {
  /* stylelint-disable */
  padding: 1px 11px;
  font-size: 12px;
  line-height: 1.66666667;
  /* stylelint-enable */
  height: 22px;
  padding: 1px 7px;
  border-radius: 0 6px 6px 0;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .rs-input-group-inside.rs-input-group-xs > .rs-input-group-btn {
    height: 24px;
  }
}
textarea.rs-input-group-inside.rs-input-group-xs > .rs-input-group-btn {
  height: auto;
}
.rs-input-group-inside.rs-input-group-xs > .rs-input-group-btn > .rs-icon {
  font-size: 12px;
  line-height: 1.66666667;
}
.rs-input-group-addon:not(:first-child):not(:last-child),
.rs-input-group-btn:not(:first-child):not(:last-child),
.rs-input-group:not(.rs-input-group-inside) .rs-input:not(:first-child):not(:last-child) {
  border-radius: 0;
}
.rs-input-group-addon {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  white-space: nowrap;
  vertical-align: middle;
  color: var(--rs-text-primary);
  padding: 8px 12px;
  font-size: 14px;
  font-weight: normal;
  line-height: 1;
  text-align: center;
  background-color: var(--rs-btn-default-bg);
  border: 1px solid var(--rs-border-primary);
  border-radius: 6px;
}
.rs-input-group-addon.rs-input-sm {
  padding: 5px 10px;
  font-size: 12px;
  border-radius: 6px;
}
.rs-input-group-addon.rs-input-xs {
  padding: 8px 8px;
  font-size: 12px;
  border-radius: 6px;
}
.rs-input-group-addon.rs-input-lg {
  padding: 10px 16px;
  font-size: 16px;
  border-radius: 6px;
}
.rs-input-group-addon input[type='radio'],
.rs-input-group-addon input[type='checkbox'] {
  margin-top: 0;
}
.rs-input-group-btn {
  position: relative;
  line-height: 1;
  white-space: nowrap;
  border-radius: 0;
}
.rs-input-group-btn > .rs-btn {
  position: relative;
}
.rs-input-group-btn > .rs-btn + .rs-btn {
  margin-left: -1px;
}
.rs-input-group-btn > .rs-btn:hover,
.rs-input-group-btn > .rs-btn:focus,
.rs-input-group-btn > .rs-btn:active {
  z-index: 2;
}
.rs-input-group-btn:first-child > .rs-btn,
.rs-input-group-btn:first-child > .rs-btn-group {
  margin-right: -1px;
  border-right: medium none;
}
.rs-input-group-btn:last-child > .rs-btn,
.rs-input-group-btn:last-child > .rs-btn-group {
  z-index: 2;
  margin-left: -1px;
}
.rs-picker-input {
  position: relative;
  border: 1px solid var(--rs-border-primary);
  border-radius: 6px;
  transition: border-color ease-in-out 0.3s;
  background-color: var(--rs-input-bg);
}
.rs-picker-input .rs-picker-toggle {
  border: none !important;
  background: transparent !important;
  height: 34px;
  cursor: text;
}
.rs-picker-input .rs-picker-toggle-clean,
.rs-picker-input .rs-picker-toggle-caret {
  top: 7px;
}
.rs-picker-input .rs-picker-toggle .rs-ripple-pond {
  display: none;
}
.rs-picker-input:not(.rs-picker-disabled) .rs-picker-toggle {
  position: absolute !important;
}
.rs-picker-input .rs-picker-toggle.rs-btn-lg {
  height: 40px;
}
.rs-picker-input .rs-picker-toggle.rs-btn-md {
  height: 34px;
}
.rs-picker-input .rs-picker-toggle.rs-btn-sm {
  height: 28px;
}
.rs-picker-input .rs-picker-toggle.rs-btn-xs {
  height: 22px;
}
.rs-picker-tag-wrapper {
  margin-right: 32px;
  min-height: 34px;
}
.rs-picker-toggle.rs-btn-lg ~ .rs-picker-tag-wrapper {
  min-height: 40px;
}
.rs-picker-toggle.rs-btn-sm ~ .rs-picker-tag-wrapper {
  min-height: 28px;
}
.rs-picker-toggle.rs-btn-xs ~ .rs-picker-tag-wrapper {
  min-height: 22px;
}
.rs-picker-cleanable .rs-picker-tag-wrapper {
  margin-right: 44px;
}
.rs-picker-search {
  border: none;
  width: 100%;
}
.rs-picker-search-input {
  /* stylelint-disable */
  padding: 7px 11px;
  font-size: 14px;
  line-height: 1.42857143;
  /* stylelint-enable */
  background: none;
  outline: none;
  border: none;
  width: 100%;
  position: relative;
  padding-right: 0;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .rs-picker-search-input {
    height: 36px;
  }
}
textarea.rs-picker-search-input {
  height: auto;
}
.rs-picker-tag .rs-picker-search-input {
  font-size: 14px;
  line-height: 1.42857143;
  padding: 2px 12px 2px 11px;
}
.rs-picker-tag .rs-picker-search-input > input {
  background: none;
  outline: none;
  border: none;
  width: 100%;
}
.rs-picker-focused .rs-picker-search-input {
  z-index: 6;
}
.rs-picker-tag .rs-picker-toggle {
  left: 0;
  cursor: text;
}
.rs-picker-tag.rs-picker-disabled {
  cursor: not-allowed;
}
.rs-picker-tag.rs-picker-disabled .rs-picker-toggle {
  position: absolute;
}
.rs-picker-tag .rs-tag {
  max-width: calc(100% - 6px);
  vertical-align: top;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.rs-picker-tag .rs-picker-search-input,
.rs-picker-tag .rs-tag {
  margin-top: 5px;
}
.rs-picker-tag.rs-picker-has-value .rs-picker-search-input {
  padding-left: 6px;
}
.rs-picker-tag .rs-tag,
.rs-picker-tag .rs-tag + .rs-tag {
  margin-left: 6px;
}
.rs-picker-tag .rs-picker-search,
.rs-picker-tag .rs-picker-search-input,
.rs-picker-tag .rs-picker-search-input > input {
  display: inline-block;
  width: auto;
  min-width: 14px;
}
.rs-picker-tag .rs-picker-search-input > input {
  padding: 0;
}
.rs-picker-tag .rs-picker-tag-wrapper {
  position: relative;
  z-index: 6;
  padding-bottom: 5px;
}
.rs-plaintext .rs-tag {
  margin-left: 6px;
  margin-bottom: 6px;
}
.rs-tag {
  display: inline-block;
  background-color: var(--rs-tag-bg);
  color: var(--rs-text-primary);
  border-radius: 6px;
  line-height: 20px;
}
.rs-tag-closable {
  position: relative;
}
.rs-tag-icon-close {
  position: absolute;
  font-size: 12px;
}
.rs-tag-default .rs-tag-icon-close:hover {
  color: var(--rs-tag-close);
}
.rs-tag-group {
  margin: -10px 0 0 -10px;
}
.rs-tag-group > .rs-tag {
  margin-top: 10px;
  margin-left: 10px;
}
.rs-tag-lg {
  padding: 5px 10px;
  font-size: 14px;
}
.rs-tag-lg .rs-tag-icon-close {
  padding: 9px 8px;
}
.rs-tag-closable.rs-tag-lg {
  padding-right: 27px;
}
.rs-tag-md {
  padding: 2px 8px;
  font-size: 12px;
}
.rs-tag-md .rs-tag-icon-close {
  padding: 6px 8px;
}
.rs-tag-closable.rs-tag-md {
  padding-right: 25px;
}
.rs-tag-sm {
  padding: 0 8px;
  font-size: 12px;
}
.rs-tag-sm .rs-tag-icon-close {
  padding: 4px 5px;
}
.rs-tag-closable.rs-tag-sm {
  padding-right: 25px;
}
.rs-tag + .rs-tag {
  margin-left: 10px;
}
.rs-tag-red {
  background-color: var(--rs-red-500);
  color: #ffffff;
}
.rs-tag-orange {
  background-color: var(--rs-orange-500);
  color: #ffffff;
}
.rs-tag-yellow {
  background-color: var(--rs-yellow-500);
  color: #ffffff;
}
.rs-tag-green {
  background-color: var(--rs-green-500);
  color: #ffffff;
}
.rs-tag-cyan {
  background-color: var(--rs-cyan-500);
  color: #ffffff;
}
.rs-tag-blue {
  background-color: var(--rs-blue-500);
  color: #ffffff;
}
.rs-tag-violet {
  background-color: var(--rs-violet-500);
  color: #ffffff;
}
.rs-btn-close {
  background: transparent;
  position: absolute;
  top: 0;
  right: 0;
  padding: 8px 8px 0;
  font-size: 12px;
  line-height: 1;
  border: none;
  outline: none !important;
  cursor: pointer;
}
.rs-btn-close .rs-icon {
  vertical-align: bottom;
}
.rs-pagination-group {
  display: flex;
  align-items: center;
}
.rs-pagination-group-lg {
  font-size: 16px;
}
.rs-pagination-group-md,
.rs-pagination-group-sm {
  font-size: 14px;
}
.rs-pagination-group-xs {
  font-size: 12px;
}
.rs-pagination-group-grow {
  flex-grow: 1;
}
.rs-pagination-group .rs-pagination {
  vertical-align: middle;
}
.rs-pagination-group-limit + .rs-pagination-group-total {
  margin-left: 18px;
}
.rs-pagination-group-skip {
  vertical-align: middle;
  display: inline-block;
  margin-left: 10px;
}
.rs-pagination-group-skip .rs-input {
  width: 46px;
  margin: 0 5px;
  display: inline-block;
}
.rs-pagination {
  display: inline-block;
  padding-left: 0;
  margin-bottom: 0;
}
.rs-pagination-lg .rs-pagination-btn {
  font-size: 16px;
  line-height: 22px;
  padding: 10px 16px;
  min-width: 42px;
}
.rs-btn-ghost.rs-pagination-lg .rs-pagination-btn {
  padding: 9px 15px;
}
.rs-btn-icon.rs-pagination-lg .rs-pagination-btn {
  padding: 11px 11px;
  line-height: 20px;
}
.rs-btn-icon.rs-pagination-lg .rs-pagination-btn > .rs-icon {
  font-size: 20px;
}
.rs-btn-icon-with-text.rs-pagination-lg .rs-pagination-btn {
  line-height: 22px;
}
.rs-btn-icon-with-text.rs-pagination-lg .rs-pagination-btn > .rs-icon {
  padding: 11px 11px;
  width: 42px;
  height: 42px;
}
.rs-btn-icon-with-text.rs-pagination-lg .rs-pagination-btn.rs-btn-icon-placement-left {
  padding: 10px 16px 10px 58px;
}
.rs-btn-icon-with-text.rs-pagination-lg .rs-pagination-btn.rs-btn-icon-placement-left > .rs-icon {
  left: 0;
  border-right: var(--rs-btn-default-border, none);
}
.rs-btn-icon-with-text.rs-pagination-lg .rs-pagination-btn.rs-btn-icon-placement-right {
  padding: 10px 58px 10px 16px;
}
.rs-btn-icon-with-text.rs-pagination-lg .rs-pagination-btn.rs-btn-icon-placement-right > .rs-icon {
  right: 0;
  border-left: var(--rs-btn-default-border, none);
}
.rs-pagination-lg .rs-pagination-btn .rs-pagination-symbol {
  height: 22px;
}
.rs-pagination-lg .rs-pagination-btn-active {
  padding: 9px 15px;
}
.rs-pagination-md .rs-pagination-btn {
  font-size: 14px;
  line-height: 20px;
  padding: 8px 12px;
  min-width: 36px;
}
.rs-btn-ghost.rs-pagination-md .rs-pagination-btn {
  padding: 7px 11px;
}
.rs-btn-icon.rs-pagination-md .rs-pagination-btn {
  padding: 10px 10px;
  line-height: 16px;
}
.rs-btn-icon.rs-pagination-md .rs-pagination-btn > .rs-icon {
  font-size: 16px;
}
.rs-btn-icon-with-text.rs-pagination-md .rs-pagination-btn {
  line-height: 20px;
}
.rs-btn-icon-with-text.rs-pagination-md .rs-pagination-btn > .rs-icon {
  padding: 10px 10px;
  width: 36px;
  height: 36px;
}
.rs-btn-icon-with-text.rs-pagination-md .rs-pagination-btn.rs-btn-icon-placement-left {
  padding: 8px 12px 8px 48px;
}
.rs-btn-icon-with-text.rs-pagination-md .rs-pagination-btn.rs-btn-icon-placement-left > .rs-icon {
  left: 0;
  border-right: var(--rs-btn-default-border, none);
}
.rs-btn-icon-with-text.rs-pagination-md .rs-pagination-btn.rs-btn-icon-placement-right {
  padding: 8px 48px 8px 12px;
}
.rs-btn-icon-with-text.rs-pagination-md .rs-pagination-btn.rs-btn-icon-placement-right > .rs-icon {
  right: 0;
  border-left: var(--rs-btn-default-border, none);
}
.rs-pagination-md .rs-pagination-btn-active {
  padding: 7px 11px;
}
.rs-pagination-sm .rs-pagination-btn {
  font-size: 14px;
  line-height: 20px;
  padding: 5px 10px;
  min-width: 30px;
}
.rs-btn-ghost.rs-pagination-sm .rs-pagination-btn {
  padding: 4px 9px;
}
.rs-btn-icon.rs-pagination-sm .rs-pagination-btn {
  padding: 7px 7px;
  line-height: 16px;
}
.rs-btn-icon.rs-pagination-sm .rs-pagination-btn > .rs-icon {
  font-size: 16px;
}
.rs-btn-icon-with-text.rs-pagination-sm .rs-pagination-btn {
  line-height: 20px;
}
.rs-btn-icon-with-text.rs-pagination-sm .rs-pagination-btn > .rs-icon {
  padding: 7px 7px;
  width: 30px;
  height: 30px;
}
.rs-btn-icon-with-text.rs-pagination-sm .rs-pagination-btn.rs-btn-icon-placement-left {
  padding: 5px 10px 5px 40px;
}
.rs-btn-icon-with-text.rs-pagination-sm .rs-pagination-btn.rs-btn-icon-placement-left > .rs-icon {
  left: 0;
  border-right: var(--rs-btn-default-border, none);
}
.rs-btn-icon-with-text.rs-pagination-sm .rs-pagination-btn.rs-btn-icon-placement-right {
  padding: 5px 40px 5px 10px;
}
.rs-btn-icon-with-text.rs-pagination-sm .rs-pagination-btn.rs-btn-icon-placement-right > .rs-icon {
  right: 0;
  border-left: var(--rs-btn-default-border, none);
}
.rs-pagination-sm .rs-pagination-btn-active {
  padding: 4px 9px;
}
.rs-pagination-xs .rs-pagination-btn {
  font-size: 12px;
  line-height: 20px;
  padding: 2px 8px;
  min-width: 24px;
}
.rs-btn-ghost.rs-pagination-xs .rs-pagination-btn {
  padding: 1px 7px;
}
.rs-btn-icon.rs-pagination-xs .rs-pagination-btn {
  padding: 6px 6px;
  line-height: 12px;
}
.rs-btn-icon.rs-pagination-xs .rs-pagination-btn > .rs-icon {
  font-size: 12px;
}
.rs-btn-icon-with-text.rs-pagination-xs .rs-pagination-btn {
  line-height: 20px;
}
.rs-btn-icon-with-text.rs-pagination-xs .rs-pagination-btn > .rs-icon {
  padding: 6px 6px;
  width: 24px;
  height: 24px;
}
.rs-btn-icon-with-text.rs-pagination-xs .rs-pagination-btn.rs-btn-icon-placement-left {
  padding: 2px 8px 2px 32px;
}
.rs-btn-icon-with-text.rs-pagination-xs .rs-pagination-btn.rs-btn-icon-placement-left > .rs-icon {
  left: 0;
  border-right: var(--rs-btn-default-border, none);
}
.rs-btn-icon-with-text.rs-pagination-xs .rs-pagination-btn.rs-btn-icon-placement-right {
  padding: 2px 32px 2px 8px;
}
.rs-btn-icon-with-text.rs-pagination-xs .rs-pagination-btn.rs-btn-icon-placement-right > .rs-icon {
  right: 0;
  border-left: var(--rs-btn-default-border, none);
}
.rs-pagination-xs .rs-pagination-btn-active {
  padding: 1px 7px;
}
.rs-pagination-btn {
  display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  transition: color 0.2s linear, background-color 0.3s linear;
  border: var(--rs-btn-default-border, none);
  color: var(--rs-btn-default-text);
  background-color: var(--rs-btn-default-bg);
  border-radius: 6px;
  padding: 8px 12px;
  overflow: hidden;
  color: var(--rs-btn-subtle-text);
  background-color: transparent;
  border: none;
  font-size: 14px;
  line-height: 20px;
  padding: 5px 10px;
  margin: 0 2px;
  position: relative;
  float: left;
  text-decoration: none;
  cursor: pointer;
  user-select: none;
  display: flex;
  justify-content: center;
}
.rs-theme-high-contrast .rs-pagination-btn {
  transition: none;
}
.rs-btn-ghost.rs-pagination-btn {
  padding: 7px 11px;
}
.rs-btn-icon.rs-pagination-btn {
  padding: 10px 10px;
  line-height: 16px;
}
.rs-btn-icon.rs-pagination-btn > .rs-icon {
  font-size: 16px;
}
.rs-btn-icon-with-text.rs-pagination-btn {
  line-height: 20px;
}
.rs-btn-icon-with-text.rs-pagination-btn > .rs-icon {
  padding: 10px 10px;
  width: 36px;
  height: 36px;
}
.rs-btn-icon-with-text.rs-pagination-btn.rs-btn-icon-placement-left {
  padding: 8px 12px 8px 48px;
}
.rs-btn-icon-with-text.rs-pagination-btn.rs-btn-icon-placement-left > .rs-icon {
  left: 0;
  border-right: var(--rs-btn-default-border, none);
}
.rs-btn-icon-with-text.rs-pagination-btn.rs-btn-icon-placement-right {
  padding: 8px 48px 8px 12px;
}
.rs-btn-icon-with-text.rs-pagination-btn.rs-btn-icon-placement-right > .rs-icon {
  right: 0;
  border-left: var(--rs-btn-default-border, none);
}
.rs-pagination-btn:focus-visible {
  outline: 3px solid var(--rs-color-focus-ring);
}
.rs-theme-high-contrast .rs-pagination-btn:focus-visible {
  outline-offset: 2px;
}
.rs-pagination-btn:hover,
.rs-pagination-btn:focus {
  color: var(--rs-btn-default-hover-text);
  background-color: var(--rs-btn-default-hover-bg);
  text-decoration: none;
}
.rs-pagination-btn:active,
.rs-pagination-btn.rs-btn-active {
  color: var(--rs-btn-default-active-text);
  background-color: var(--rs-btn-default-active-bg);
}
.rs-pagination-btn:disabled,
.rs-pagination-btn.rs-btn-disabled {
  cursor: not-allowed;
  color: var(--rs-btn-default-disabled-text);
  background-color: var(--rs-btn-default-disabled-bg);
}
.rs-theme-high-contrast .rs-pagination-btn:disabled,
.rs-theme-high-contrast .rs-pagination-btn.rs-btn-disabled {
  opacity: 0.5;
}
@media not all and (min-resolution: 0.001dpcm) {
  .rs-pagination-btn {
    /* stylelint-disable */
    -webkit-mask-image: -webkit-radial-gradient(white, black);
    /* stylelint-enable */
  }
}
.rs-picker-default .rs-pagination-btn {
  transition: border-color ease-in-out 0.3s;
}
.rs-theme-high-contrast .rs-picker-default .rs-pagination-btn {
  transition: none;
}
.rs-picker-default:not(.rs-picker-disabled) .rs-pagination-btn:hover,
.rs-picker-default:not(.rs-picker-disabled) .rs-pagination-btn:focus,
.rs-picker-default:not(.rs-picker-disabled) .rs-pagination-btn-active {
  border-color: var(--rs-input-focus-border);
}
.rs-picker-subtle .rs-pagination-btn {
  color: var(--rs-btn-subtle-text);
  background-color: transparent;
  border: none;
  transition: none;
}
.rs-picker-subtle .rs-pagination-btn:hover,
.rs-picker-subtle .rs-pagination-btn:focus {
  color: var(--rs-btn-subtle-hover-text);
  background-color: var(--rs-btn-subtle-hover-bg);
}
.rs-picker-subtle .rs-pagination-btn:active,
.rs-picker-subtle .rs-pagination-btn.rs-btn-active {
  color: var(--rs-btn-subtle-active-text);
  background-color: var(--rs-btn-subtle-active-bg);
}
.rs-picker-subtle .rs-pagination-btn:disabled,
.rs-picker-subtle .rs-pagination-btn.rs-btn-disabled {
  color: var(--rs-btn-subtle-disabled-text);
  background: none;
}
.rs-theme-high-contrast .rs-picker-subtle .rs-pagination-btn:disabled,
.rs-theme-high-contrast .rs-picker-subtle .rs-pagination-btn.rs-btn-disabled {
  opacity: 0.5;
}
.rs-picker-subtle .rs-pagination-btn-active {
  background-color: var(--rs-btn-subtle-hover-bg);
  color: var(--rs-btn-subtle-hover-text);
}
.rs-pagination-btn:hover,
.rs-pagination-btn:focus {
  color: var(--rs-btn-subtle-hover-text);
  background-color: var(--rs-btn-subtle-hover-bg);
}
.rs-pagination-btn:active,
.rs-pagination-btn.rs-btn-active {
  color: var(--rs-btn-subtle-active-text);
  background-color: var(--rs-btn-subtle-active-bg);
}
.rs-pagination-btn:disabled,
.rs-pagination-btn.rs-btn-disabled {
  color: var(--rs-btn-subtle-disabled-text);
  background: none;
}
.rs-theme-high-contrast .rs-pagination-btn:disabled,
.rs-theme-high-contrast .rs-pagination-btn.rs-btn-disabled {
  opacity: 0.5;
}
.rs-btn-ghost.rs-pagination-btn {
  padding: 4px 9px;
}
.rs-btn-icon.rs-pagination-btn {
  padding: 7px 7px;
  line-height: 16px;
}
.rs-btn-icon.rs-pagination-btn > .rs-icon {
  font-size: 16px;
}
.rs-btn-icon-with-text.rs-pagination-btn {
  line-height: 20px;
}
.rs-btn-icon-with-text.rs-pagination-btn > .rs-icon {
  padding: 7px 7px;
  width: 30px;
  height: 30px;
}
.rs-btn-icon-with-text.rs-pagination-btn.rs-btn-icon-placement-left {
  padding: 5px 10px 5px 40px;
}
.rs-btn-icon-with-text.rs-pagination-btn.rs-btn-icon-placement-left > .rs-icon {
  left: 0;
  border-right: var(--rs-btn-default-border, none);
}
.rs-btn-icon-with-text.rs-pagination-btn.rs-btn-icon-placement-right {
  padding: 5px 40px 5px 10px;
}
.rs-btn-icon-with-text.rs-pagination-btn.rs-btn-icon-placement-right > .rs-icon {
  right: 0;
  border-left: var(--rs-btn-default-border, none);
}
.rs-pagination-btn .rs-pagination-symbol {
  display: flex;
  align-items: center;
  height: 20px;
}
.rs-theme-high-contrast .rs-pagination-btn {
  transition: none;
  color: var(--rs-pagination-item-text);
}
.rs-theme-high-contrast .rs-pagination-btn:hover,
.rs-theme-high-contrast .rs-pagination-btn:focus-visible {
  color: var(--rs-pagination-item-current-text);
  text-decoration: underline;
}
.rs-pagination-btn.rs-pagination-btn-active {
  color: var(--rs-btn-ghost-text);
  background-color: transparent;
  border: 1px solid var(--rs-btn-ghost-border);
}
.rs-pagination-btn.rs-pagination-btn-active:hover,
.rs-pagination-btn.rs-pagination-btn-active:focus {
  color: var(--rs-btn-ghost-hover-text);
  background-color: transparent;
  border-color: var(--rs-btn-ghost-hover-border);
}
.rs-pagination-btn.rs-pagination-btn-active:active,
.rs-pagination-btn.rs-pagination-btn-active.rs-btn-active {
  color: var(--rs-btn-ghost-active-text);
  background-color: transparent;
  border-color: var(--rs-btn-ghost-active-border);
}
.rs-pagination-btn.rs-pagination-btn-active:disabled,
.rs-pagination-btn.rs-pagination-btn-active.rs-btn-disabled {
  color: var(--rs-btn-ghost-text);
  background-color: transparent;
  opacity: 0.3;
}
.rs-theme-high-contrast .rs-pagination-btn.rs-pagination-btn-active:disabled,
.rs-theme-high-contrast .rs-pagination-btn.rs-pagination-btn-active.rs-btn-disabled {
  opacity: 0.5;
}
.rs-theme-high-contrast .rs-pagination-btn.rs-pagination-btn-active {
  color: var(--rs-pagination-item-current-text);
  text-decoration: underline;
}
/* rtl:begin:ignore */
[dir='rtl'] .rs-pagination-btn [class*='rs-icon-page'] {
  transform: rotate(180deg);
}
/* rtl:end:ignore */
